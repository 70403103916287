import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from '../../helpers/consts'

export const Container = styled.header`
  width: 100%;
  position: ${({ view }) => (view === "main" ? "absolute" : "fixed")};
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: ${colors.darkBlue};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem 0;
  transition: padding 0.3s ease-out;

  @media (min-width: 48em) {
    padding: ${({ view }) => view === "main" ? "2.8rem 0" : "1rem 0"};
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const LogoTitle = styled.h2`
  max-width: 8rem;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${colors.white};
  margin-left: 1.2rem;
  max-width: 9rem;
`;

export const Logo = styled.img.attrs({
  alt: "",
})`
  min-width: 3.6rem;
  max-width: 3.6rem;
`;

export const LogoLink = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  transition: box-shadow 0.2s ease-out;
  justify-self: flex-start;
`;

export const ButtonContent = styled.div`
  min-width: 2.2rem;
  max-width: 2.2rem;
  min-height: 1.6rem;
  max-height: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0;
`;

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${colors.black};
  transition: background-color 0.2s ease-out;
`;

export const Button = styled.button.attrs({
  type: "button",
})`
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem;
  margin-left: 1.2rem;
  border-radius: 0.6rem;
  background-color: ${colors.lightBlue};
  border: 1px solid #0d6efd;
  transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;

  &:hover,
  &:focus {
    transform: scale(1.08);
  }
  &:active {
    transform: scale(0.98);
  }

  &:hover
  ${Line},
  &:focus
  ${Line} {
    background-color: ${colors.orange};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5; 
  }
`;

export const AdditionalNavContent = styled.div`
  width: 100%;
  max-height: ${({visible}) => visible ? '30rem' : '0'};
  overflow: ${({hasOverflow}) => hasOverflow ? 'visible' : 'hidden'};
  transition: max-height 0.4s ease-out;
  padding-bottom: 1rem;
`;

export const AdditionalNavContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;