import {useTranslation} from 'react-i18next'
import {themeConfig, contactsURL} from '../theme'
import {Link as RouterLink} from 'react-router-dom'

const { COMPANY_NUMBER } = themeConfig

export const TermOfUse = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.TermsOfUse.Legal`)}</h1>
            <h3>Terms of Use - GUP-CH AG ("GUP"), operating under the trade name "Global Unit Pay"</h3>
            <h4>Last updated 16 October 2024</h4>
            <p>
                By using this website ("Site"), registering for a GUP Account ("Account"), or using any 
                other GUP Services, you ("you, your, or yourself") are agreeing to accept and comply with 
                the terms and conditions of use stated below ("Terms of Use"). You should read the Terms 
                of Use carefully before using this Site or any of the GUP Services.
            </p>
            <p>"Global Unit Pay" is the trade name of GUP-CH AG.</p>
            <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of
               the terms, you may not access the Service.</p>
            <p>
                As used in these Terms of Use, "GUP" refers to the company GUP-CH AG, a financial intermediary
                registered with the Swiss Companies Register under company number{' '}
                <a
                    href={`https://zg.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=${COMPANY_NUMBER}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {COMPANY_NUMBER}
                </a>
                , with its registered address at Chaltenbodenstrasse 16, 8834 Schindellegi, Switzerland. GUP operates
                under the tradename Global Unit Pay.
            </p>
            <p>
                GUP-CH AG is affiliated to SO-FIT a financial intermediary within the meaning of Article 2 para. 3 
                of the Anti-Money Laundering Act (AMLA).
            </p>
            <p>SO-FIT is a self-regulatory organization recognized by the Swiss Federal Financial Markets Supervisory Authority (FINMA).</p>
            <p>GUP is not a bank and, as such, is not part of a depositor protection scheme.</p>
            <p>References to GUP are also references to its owners, directors, investors, and employees.</p>
            <p>All customer funds are held by licensed banks.</p>
            <p>GUP allows buyers ("Buyers") and sellers ("Sellers") to use its Payment Institution capabilities to buy
               and sell cryptocurrencies through authorized providers.</p>
            <p>The Service operated by GUP allows all customers of the Service ("Members") to:</p>

            <div className="dottedList">
                <p>buy and sell Bitcoin or other cryptocurrencies (herein "Bitcoin" or "cryptocurrency").</p>
                <p>transfer cryptocurrency and fiat currency to other Members and to non-members who must become Members
                   prior to withdrawing such cryptocurrency or fiat money.</p>
                <p>exchange FIAT for various currencies.</p>
                <p>open IBAN Bank account with authorized Banks partners.</p>
                <p>order Mastercard prepaid cards.</p>
                <p>
                    Depending on your country of residence, you may not be able to use all the functions of the Site.
                    It is your responsibility to follow the rules and regulations applicable in your country of
                    residence and/or country from which you access this Site and Services.
                    As long as you agree to and comply with these Terms of Use, GUP grants you the personal,
                    non-exclusive, non-transferable, non-sublicensable,
                    and limited right to enter and use the Site and the Service.
                </p>
            </div>

            <h3>IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS OUTLINED IN THIS AGREEMENT, DO NOT ACCESS THIS SITE AND DO
                NOT USE OUR SERVICE.</h3>
            <p>By registering for an Account, you expressly represent and warrant:</p>
            <div className="dottedList">
                <p>That you have accepted these Terms of Use; and</p>
                <p>That you are at least 18 years of age and have the full capacity to accept these Terms of Use and
                   enter into a transaction involving cryptocurrencies.</p>
            </div>

            <h3>Risk Warning</h3>
            <p>
                The trading of goods and products, real or virtual, as well as virtual currencies, involves a
                significant risk. Prices can and do fluctuate on any given day.
                Such price fluctuations may increase or decrease the value of your assets at any given moment.
                Any currency, virtual or not, may be subject to large swings in value and may even become worthless.
                There is an inherent risk that losses will occur as a result of buying, selling, or trading anything on
                the market.
            </p>
            <p>
                Trading cryptocurrencies also has special risks not generally shared with official currencies, goods, or
                commodities in a market.
                Unlike most currencies that are backed by governments, other legal entities, or commodities such as gold
                or silver,
                cryptocurrencies are a unique type of Internet digital currency backed by technology and trust.
                There is no central bank that can issue more currency or take corrective measures to protect the value
                of cryptocurrencies in a crisis.
            </p>
            <p>
                Instead, cryptocurrencies are an as-yet autonomous and largely unregulated global system of firms and
                individuals.
                Traders put their trust in a digital, decentralized, and partially anonymous system that relies on
                peer-to-peer networking and cryptography to maintain its integrity.
            </p>
            <p>
                The trading of cryptocurrencies is often susceptible to irrational bubbles or loss of confidence, which
                could collapse demand relative to supply.
                For example, confidence might collapse in cryptocurrencies because of unexpected changes imposed by
                software developers or others, a government crackdown,
                the creation of superior competing alternative currencies, or a deflationary or inflationary spiral.
                Confidence might also collapse because of technical problems:
                if the anonymity of the system is compromised, if money is lost or stolen, or if hackers or governments
                are able to prevent any transactions from settling.
            </p>
            <p>
                There may be additional risks that we have not foreseen or identified in these Terms of Use.
                You should carefully assess whether your financial situation and tolerance for risk are suitable for
                buying, selling, or trading cryptocurrencies.
            </p>
            <p>
                We use our banking providers in order to receive client funds and make payments. Our banking providers
                DO NOT transfer,
                exchange, or provide any services in connection with cryptocurrencies.
            </p>

            <h3>Limited Right of Use</h3>
            <p>
                Unless otherwise specified, all Materials on this Site are the property of GUP and are protected by
                copyright, trademark, and other applicable laws.
                You may view, print, and/or download a copy of the Materials from this Site on any single computer
                solely for your personal, informational,
                and/or non-commercial use, provided you comply with all copyright and other proprietary notices.
            </p>
            <p>
                The trademarks, service marks, and logos of GUP,
                including but not limited to "Global Unit Pay" and others used in this Site ("Trademarks") are the
                property of GUP and their respective owners.
                The software, text, images, graphics, data, prices, trades, charts, graphs, video, and audio used on
                this Site belong to GUP.
                The Trademarks and Materials should not be copied, reproduced, modified, republished, uploaded, posted,
                transmitted, scraped,
                collected, or distributed in any form or by any means, whether manual or automated.
                The use of any such Materials on any other Site or networked computer environment for any other purpose
                is strictly prohibited;
                any such unauthorized use may violate copyright, trademark, and other applicable laws and could result
                in criminal or civil penalties.
            </p>

            <h3>Maintaining Your Account: Our Rules</h3>
            <p>
                This Site is for your personal and non-commercial use only. We are vigilant in maintaining the security
                of our Site and Service.
                By registering with us, you agree to provide GUP with current, accurate, and complete information about
                yourself,
                as prompted by the registration process, and to keep such information updated. Failure to do so
                constitutes a breach of these Terms of Use,
                which may result in immediate termination of your Account on our Service. You further agree that you
                will not use any Account other than your own,
                access the Account of any other Member at any time, or assist others in gaining unauthorized access.
            </p>
            <p>
                The creation or use of Accounts without obtaining prior express permission from GUP will result in the
                immediate suspension of all said Accounts,
                as well as all pending purchase/sale offers. Any attempt to do so or to assist others (Members or
                otherwise), or the distribution of instructions,
                software, or tools for that purpose, will result in the Accounts of such Members being terminated.
                Termination is not the exclusive remedy for such a violation, and GUP may elect to take further action
                against you.
            </p>
            <p>
                You are also responsible for maintaining the confidentiality of your Account information, including your
                password, and safeguarding your Account and all activity,
                including Transactions that are posted to your Account. Cryptocurrency transactions are irreversible
                once sent, so be sure to carefully confirm the receiving address before initiating a Bitcoin
                transaction.
                Any actions on the Site, transactions, orders, and operations initiated from your Account or using your
                password (1) will be considered to have been made by you,
                and (2) is irrevocable once validated using your password or made through your Account. If there is
                suspicious activity related to your Account, we may,
                but are not obligated to, request additional information from you, including authenticating documents,
                and freeze any transactions pending our review.
                You are obligated to comply with these security requests or accept the termination of your Account.
                You are required to notify GUP immediately of any unauthorized use of your Account or password or any
                other breach of security by email to compliance@globalunitpay.ch.
                The Account of any Member who violates these rules may be terminated and the Member held liable for
                losses incurred by GUP or any user of the Site.
            </p>
            <p>
                Lastly, you agree that you will not use the Service to perform criminal activity of any sort, including
                but not limited to money laundering,
                illegal gambling operations, terrorist financing, malicious hacking, or any criminal or illegal
                activity.
            </p>
            <p>The minimum allowable buy or sell is EUR 5.</p>

            <h3>FEES</h3>
            <p>Our fees charged can be found on each respective function page.</p>
            <h3>VERIFICATION OF ACCOUNTS</h3>
            <p>
                The creation and use of your Account is subject to verifications, as required by statutory and
                regulatory obligations incumbent on GUP.
                You agree to provide us with the information we request for the purposes of identity verification,
                compliance with know-your-customer rules,
                as well as detection of money laundering, terrorism financing, fraud, or any other financial crime.
                The requested information may include Personal Data (please refer to our Privacy Policy By providing us
                with the information we request,
                you confirm that it is true and accurate and agree to inform us in case of change concerning such
                information.
                Your Account will be blocked until we are satisfied with the information you have provided and determine
                in our sole discretion that it is sufficient to validate your Account.
                In the meantime, you will not be allowed to terminate your Account or request the deletion of the
                Personal Data processed in the course of verification operations.
                GUP utilizes and thus shares information with a third-party AML verification tool in addition to its
                internal identification screening procedures.)
            </p>

            <h3>For IBAN Opening</h3>
            <p>By using the Platform, you agree to the external authorized banks and their Privacy Policy.</p>

            <h3>Card issue</h3>
            <p>
                GUP Mastercard® prepaid card ("Card") and the additional services are provided by Creditco UAB under
                their electronic money institution licenses.
                The Card is issued by Paynovate SA pursuant to a license by Mastercard. Paynovate SA is an e-money
                issuer regulated by the National Bank of Belgium.
                Paynovate SA is registered with the Banque-Carrefour des Entreprises under number BE0506 763 929.
                Paynovate SA is a principal member of Mastercard.
            </p>

            <h3>Termination</h3>
            <p>We may terminate or suspend your Account immediately, without prior notice or liability, for any reason
               whatsoever, including without limitation if you breach the Terms of Use.</p>
            <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your
               Account, you may simply discontinue using the Service.</p>

            <h3>Availability of Services</h3>
            <p>
                All services are provided without warranty of any kind, either express or implied and in particular
                without implied warranties of merchantability and fitness for a particular purpose.
                We do not guarantee that this Site will be available 100% of the time to meet your needs. We will strive
                to provide you with the Service as soon as possible,
                but there are no guarantees that access will not be interrupted or that there will be no delays,
                failures, errors, omissions, or loss of transmitted information.
            </p>
            <p>
                We will use reasonable endeavours to ensure that the Site can be accessed by you in accordance with
                these Terms of Use.
                However, we may suspend the use of the Site for maintenance and will make reasonable efforts to give you
                notice of this.
                You acknowledge that this may not be possible in an emergency,
                and accept the risks associated with the fact that you may not always be able to use the Site or carry
                out urgent transactions using your Account.
            </p>

            <h3>APIs and Widgets</h3>
            <p>
                We may provide certain parties with access to specific data and information through our API (Application
                Programming Interface) or widgets.
                We also may provide widgets for your use to enter our data on your Site. You are free to use these in
                their original unmodified and un-altered state.
            </p>

            <h3>External Websites</h3>
            <p>
                GUP makes no representations whatsoever about any external or third-party website you may access through
                the Site.
                Occasionally, the GUP website may provide references or links to other websites ("External Websites").
                We do not control these External Websites or third-party sites or any of the content contained therein.
                You agree that we are in no way responsible or liable for the External Websites referenced or linked
                from the GUP website,
                operating as Global Unit Pay, including but not limited to website content, policies, failures,
                promotions,
                products, opinions, advice, statements, prices, activities and advertisements, services, or actions,
                and/or any damages,
                losses, failures, or problems caused by, related to, or arising from those sites. You shall bear all
                risks associated with the use of such content.
            </p>
            <p>
                External Websites have separate and independent terms of use and related policies. We request that you
                review the policies, rules, terms,
                and regulations of each site that you visit. It is up to you to take precautions to ensure that whatever
                you select for your use is free of items such as viruses,
                worms, Trojan horses, and other items of destructive nature.
            </p>

            <h3>Financial Advice</h3>
            <p>
                GUP does NOT provide any investment advice in connection with the Services described in these Terms of
                Use. We may provide information on the price, range,
                and volatility of cryptocurrencies that are available on our Platform and events that have affected the
                price of such cryptocurrencies,
                but this must not be considered investment advice, nor should it be construed as such.
                Any decision to purchase or sell cryptocurrencies or other commodities or currencies available on this
                website is solely your decision,
                and we shall not be liable for any loss suffered.
            </p>

            <h3>Financial Regulation</h3>
            <p>
                GUP-CH AG is affiliated to SO-FIT a financial intermediary within the meaning of article 2 para. 3 
                of the Anti-Money Laundering Act (AMLA).
            </p>
            <p>
                SO-FIT is a self-regulatory organization recognized by the Swiss Federal Financial Markets Supervisory 
                Authority (FINMA).
            </p>

            <h3>Data protection</h3>
            <p>
                Privacy is very important to us. Full details of our&nbsp;<RouterLink to="/legal/privacy-policy">Privacy
                                                                                                           Policy</RouterLink> can
                be found on our&nbsp;
                <RouterLink to="/legal/privacy-policy">Privacy Policy</RouterLink> page. We recommend that you read the&nbsp;
                <RouterLink to="/legal/privacy-policy">Privacy Policy</RouterLink> carefully,
                so that you know the data that we collect, how we use the data, and who we share your data with.
            </p>

            <h3>Disclosures to Legal Authorities and Authorized Financial Institutions</h3>
            <p>We may share your Personal Data with law enforcement, data protection authorities, government officials,
               and other authorities when:</p>
            <div className="dottedList">
                <p>Required by law;</p>
                <p>Compelled by subpoena, court order, or other legal procedure;</p>
                <p>We believe that disclosure is necessary to prevent damage or financial loss;</p>
                <p>Disclosure is necessary to report suspected illegal activity; or</p>
                <p>
                    Disclosure is necessary to investigate violations of our&nbsp;<RouterLink to="/legal/terms-of-use">Terms
                                                                                                                 of
                                                                                                                 Use</RouterLink>&nbsp;or&nbsp;
                    <RouterLink to="/legal/privacy-policy">Privacy Policy</RouterLink>.
                </p>
            </div>
            <p>
                For further information on how we process your Personal Data, please refer to the&nbsp;
                <RouterLink to="/legal/privacy-policy">Privacy Policy</RouterLink>.
            </p>

            <h3>Unclaimed Property</h3>
            <p>
                If we hold cash or cryptocurrency, and we are unable to contact you and have no record of your use of
                the GUP Services for several years,
                applicable law may require us to report the cash or cryptocurrency as unclaimed property to the
                authorities in certain jurisdictions.
                We will try to locate you at the address shown in our records, but if we are unable to,
                we may be required to deliver any such cash or cryptocurrency to the authorities in certain
                jurisdictions as unclaimed property.
                We reserve the right to deduct a dormancy fee or other administrative charges from such unclaimed funds
                as permitted by applicable law.
            </p>

            <h3>Jurisdiction/Governing Law</h3>
            <p>
                The Terms of Use shall be governed and construed in accordance with the laws of Switzerland, without
                regard to its conflict of law provisions.
                The parties hereto agree to irrevocably submit to the exclusive jurisdiction of the courts of
                Switzerland.
            </p>
            <p>
                Our failure to enforce any right or provision of these Terms of Use will not be considered a waiver of
                those rights.
                If any provision of these Terms of Use is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms of Use will remain in effect.
                These Terms of Use constitute the entire agreement between us regarding our Service, and supersede and
                replace any prior agreements we might have between us regarding the Service.
            </p>

            <h3>Limitation of Liability</h3>
            <p>
                To the extent permitted by law, GUP will not be held liable for any damages, loss of profit, loss of
                revenue, loss of business, loss of opportunity, loss of data,
                indirect or consequential loss unless the loss suffered arose from negligence or wilful deceit or fraud.
                Nothing in these Terms of Use excludes or limits the liability of either party for fraud,
                death, or personal injury caused by its negligence, breach of terms implied by operation of law, or any
                other liability which may not be limited or excluded by law.
                Although GUP endeavors to provide accurate and timely information on the Site, the Site may not always
                be entirely accurate, complete, or current and may include errors.
                We may change or update the Site at any time without notice, and you should accordingly verify with
                independent sources all information before relying on it to take decisions or actions.
                You remain entirely responsible for your decisions and actions.
            </p>
            <p>
                Subject to the foregoing, the aggregate liability for claims against GUP based on events arising from or
                in connection with any single Member's use of the Site and/or Service,
                whether in contract or tort (including negligence) or otherwise, shall in no circumstances exceed the
                greater of either (a) the total amount held on Account for the Member making a claim less any amount of
                Commission that may be due and payable in respect of such Account; or (b) 100% of the amount of the
                Transaction(s) that are the subject of the claim less any amount of Commission that may be due and
                payable in
                respect of such Transaction(s).
            </p>

            <h3>Legal Disclaimer</h3>
            <p>
                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE"
                basis. The Service is provided without warranties of any kind, whether express or implied, including,
                but not limited to, implied warranties of merchantability, fitness for a particular purpose,
                non-infringement, or course of performance.
            </p>
            <p>
                GUP its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function
                uninterrupted, secure, or available at any particular time or location; b) any errors or defects will be
                corrected;
                c) the Service is free of viruses or other harmful components; or d) the results of using the Service
                will meet your requirements.
            </p>

            <h3>Indemnity</h3>
            <p>
                To the full extent permitted by applicable law, you hereby agree to indemnify GUP and its partners
                against any action, liability, cost, claim, loss, damage, proceeding, or expense suffered or
                incurred if directly or indirectly arising from your use of GUP's Sites, your use of the Service, or
                your violation of these Terms of Use.
            </p>

            <h3>Miscellaneous</h3>
            <p>
                If we are unable to perform the Services outlined in the Terms of Use due to factors beyond our control,
                including but not limited to an event of force majeure, change of law, or
                change in sanctions policy, we shall not be liable for the Services provided under this agreement during
                the time period coincident with the event.
            </p>

            <h3>Modification of Terms</h3>
            <p>
                GUP reserves the right to change, add, or remove parts of these Terms of Use at any time and at its sole
                discretion. You will be notified of any changes in advance through your Account.
                Upon such notification, it is your responsibility to review the amended Terms of Use. Your continued use
                of the Site following the posting of a notice of changes to the Terms of Use signifies
                that you accept and agree to the changes and that all subsequent transactions by you will be subject to
                the amended Terms of Use.
            </p>

            <h3>Definitions</h3>
            <p>
                Account. The contractual arrangement wherein a GUP Member has accepted our&nbsp;
                <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>&nbsp;and&nbsp;
                <RouterLink to="/legal/privacy-policy">Privacy Policy</RouterLink>, and received approval to use GUP Services,
                including the purchase and sale of cryptocurrencies, and to perform associated Transactions.
            </p>
            <p>
                Bitcoins. The Peer-to-Peer Internet digital currency is further described at{' '}
                <a
                    href="https://Bitcoin.org"
                    target="_blank"
                    rel="noreferrer"
                >
                    Bitcoin.org
                </a>
                .
            </p>
            <p>Cryptocurrencies. All digital currencies, including but not limited to Bitcoin.</p>
            <p>Buyer(s). Member(s) that are submitting an offer to buy cryptocurrency through the Service.</p>
            <p>Commission or Fee. Refers to the fee which is payable to GUP on each Transaction, such as a buy or sell
               of cryptocurrency. You can review our Fees on our Fees page.</p>
            <p>Member(s). Refers to Buyers and Sellers as well as any holder of an Account.</p>
            <p>
                Personal Data. Information that identifies an individual, such as name, address, email address, trading
                information, and banking details.
                "Personal Data" does not include anonymized and/or aggregated data that does not identify a specific
                user.
            </p>
            <p>
                Price. The "price per coin" for which Members are willing to purchase or sell cryptocurrencies, using
                the Service in a purchase or sell transaction.
                The Price may be expressed in any of the currencies deposited by Members in their Account and supported
                by the Service. See our Site for a full list of currencies.
            </p>
            <p>GUP may not offer currencies other than Euros at this time.</p>
            <p>Seller(s). Member(s) that submit an offer to sell cryptocurrencies through the Service.</p>
            <p>Service(s). The technological Platform, functional rules, and market are managed by GUP to permit Sellers
               and Buyers to purchase and sell cryptocurrencies.</p>
            <p>Transaction. Includes the following:</p>
            <div className="dottedList">
                <p>The action between the Member to Buy and/or Sell cryptocurrencies through the Service for currencies
                   at the then-current market rate.</p>
                <p>The transfer of cryptocurrencies between Members ("Transfer Transaction");</p>
                <p>The transfer of currencies among Members ("Currency Transfer Transaction"); and</p>
                <p>GUP may not offer all these transaction types at this time.</p>
            </div>
            <p>GUP may not offer all these transaction types at this time.</p>
            <p>Transaction Price. The total price paid by the Buyer for each Transaction performed through the
               Service.</p>

            <h3>Consent to Use of Cookies</h3>
            <p>
                This website uses cookies to ensure you have the best experience. By continuing to use this site, you
                consent to our&nbsp;
                <RouterLink to="/legal/cookie-statement">Cookie Statement</RouterLink>. You can disable cookies at any time,
                by changing your browser settings. See our&nbsp;
                <RouterLink to="/legal/cookie-statement">Cookie Statement</RouterLink>&nbsp;for instructions on how to disable
                cookies, and the effect this will or may have on the Services.
            </p>

            <h3>Changes</h3>
            <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms of Use at any time. If a
                revision is material, we will try to provide at least 30 days
                notice prior to any new terms taking effect. What constitutes a material change will be determined at
                our sole discretion.
            </p>
            <p>
                By continuing to access or use our Service after those revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, please stop using the Service.
            </p>

            <h3>Email</h3>
            <p>
                Unencrypted email messages sent over the Internet are not secure, and GUP is not responsible for any
                damages incurred as a result of sending email messages in this way.
                We suggest existing Members always log in to the GUP site and use the internal support messaging system
                by clicking "Support" in the footer menu or, for non-Members,
                always send email in encrypted formats. You are welcome to send PGP encrypted emails to us. The
                instructions and keys to do so are available upon request.
                Please never send us or anyone else your password by email.
            </p>
            <p>If you send unencrypted or unsecured email or other types of communications to us, we may respond using
               the same channels, and you hereby accept the risks associated therewith.</p>
            <p>
                GUP will never contact you from an email account that does not end with @globalunitpay.ch. If anyone,
                including a person that you know to be associated with GUP,
                ever contacts you via email with an address that does not end with @globalunitpay.ch, please notify our
                compliance department at compliance@globalunitpay.ch immediately.
            </p>

            <h3>Contact Us</h3>
            <p>
                If you are a Member, and have any questions relating to these Terms of Use, your rights and obligations
                arising from these Terms, and/or your use of the Site and the Service, your
                Account, or any other matter, please&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;via our internal secure messaging platform, from within your Account by
                clicking "Support" in the footer after logging in or, if you cannot&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;using our internal secure messaging platform,
                you can email us via the contact form on the&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;page of our website. If you email us without using our internal secure
                messaging platform, please send PGP encrypted emails to us. The instructions and keys to do so are
                available upon request. For more information about email communications,
                see the section titled "Email" above.
            </p>
        </div>
    )
}
