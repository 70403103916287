import {useTranslation} from 'react-i18next'
import {themeConfig, contactsURL, contactsEmail} from '../theme'
import {Link as RouterLink} from 'react-router-dom'

const { COMPANY_NUMBER } = themeConfig

export const PrivacyPolicy = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.PrivacyPolicy.Legal`)}</h1>
            <h4>Last updated 16 October 2024</h4>
            <p>This privacy notice applies to GUP-CH AG, which sometimes uses trading names including GUP (together
               "GUP" or "we" or "our").</p>
            <p>
                This notice shows our commitment to protecting your privacy rights and sets out the basis on which any
                personal data we collect from you
                or that you provide to us will be processed by us. It applies to:
            </p>
            <div className="dottedList">
                <p>the website/portal features and services provided to you when you visit our websites, portals, or our
                   payment panels our clients may use on their websites;</p>
                <p>your use of software, including terminals, mobile and desktop applications provided by GUP; and</p>
                <p>email, other electronic messages, including SMS, telephone, web chat, website/portal, and other
                   communications between you and GUP.</p>
            </div>
            <p>Please read the following carefully to understand our views and practices regarding your personal data
               and how we will treat it.</p>
            <p>
                GUP undertakes a "layered approach" to explaining its privacy practices. This means that we endeavor to
                initially provide you with the key
                privacy information in the format of this "short form" privacy notice. To review our full-size privacy
                notice, please see our Comprehensive Privacy Notice.
                Our contact details are provided at the end of this privacy notice for feedback or any privacy inquiries
                you may have.
            </p>

            <h3>INFORMATION WE MAY COLLECT FROM YOU</h3>
            <p>
                You may provide some information to us, for example, when you enquire for or make an application for the
                GUP Services, register to use and/or use
                any GUP Services, by filling in forms on one of our websites, participating in one of our events or by
                corresponding with us via phone, email,
                web chat or otherwise. We may collect some of your personal data through automatic means, for example,
                by using cookies when you visit our websites.
                Read our <RouterLink to="/legal/cookie-statement">Cookie Statement</RouterLink> to learn more.
                We may also obtain personal data about you from third parties, for example credit reference information
                and data from fraud prevention agencies.
            </p>

            <h3>USES OF THE INFORMATION</h3>
            <p>
                We may use the information we collect about you to provide you with the products we offer, to notify you
                about changes to our products, and to improve our products.
                We also use this information to provide you with information about other products we or selected third
                parties offer which is similar to the ones you have used or enquired about,
                or we think may be of interest to you. If you use one of our financial products, we will also use your
                information to assess your
                financial situation and to try to identify and prosecute possible frauds.
            </p>

            <h3>DISCLOSURE OF YOUR INFORMATION</h3>
            <p>We will not disclose your personal data to anyone except as described in this notice.</p>
            <p>
                We may share your personal data with third parties to provide you with the products we offer, including
                service providers, credit reference agencies,
                and financial institutions. We may also share your personal data with third parties to prevent crime and
                reduce risk,
                if required to do so by law, where we deem it appropriate to do so, to respond to legal process, or to
                protect the rights or property of GUP, our customers, or others.
            </p>

            <h3>STORAGE OF YOUR PERSONAL DATA AND DATA SECURITY MEASURES</h3>
            <p>
                The information GUP collects from you may be transferred to, stored at, and processed by recipients
                located in destinations outside the European Economic Area ("EEA").
                These countries may have data protection standards that are different from (and, in some cases, lower
                than) those in which you reside. We will take all steps necessary
                to ensure that the recipients will use and protect your information in the same secure way that we do.
            </p>
            <p>
                If we have given you (or if you have chosen) a password, access code, or any other secure means of
                access or authentication that enables you to access certain parts of our site,
                you are responsible for keeping this password confidential and complying with our instructions. You must
                not share your password or other login credentials with anyone,
                and you hereby authorize GUP to act upon instructions and information from any person that enters your
                credentials.
            </p>
            <p>
                The transmission of information via the Internet is not completely secure. Although we will do our best
                to protect your personal data, we cannot guarantee the security
                of your data transmitted to our site, unless you are communicating with us through a secure channel
                provided by us. Once we have received your information,
                we will use strict procedures and security features in an effort to prevent unauthorized access.
            </p>

            <h3>YOUR RIGHTS</h3>
            <p>
                Where applicable, you may have certain rights under your local data protection legislation, including
                the right to information, the right to access, correct, update, or delete your personal data;
                object to or restrict its processing; where applicable request to transfer certain of your personal data
                to another service provider (so called, data portability);
                or to revoke any consent you have given.
            </p>
            <p>
                The exercise of such rights usually requires that you clearly prove your identity (e.g. by means of a
                copy of your identity card if your identity is otherwise not clear or cannot be verified).
                To exercise your rights, you can contact us at the address provided below.
            </p>
            <p>
                However, please note that there are exceptions to those rights. For a full explanation of the rights
                available, including further information on data retention, purposes of processing,
                types of personal data processed, rights for residents in Switzerland and how we strive to keep your
                personal data safe, please refer to our

                <a href='#comprehensive'> Comprehensive Privacy Notice</a>.
            </p>

            <h3>AUTOMATED DECISION MAKING</h3>
            <p>Automated decisions mean that a decision concerning you is made automatically on the basis of a computer
               determination (using software algorithms), without our human review.</p>
            <p>
                As a rule, we do not make any decisions on the establishment and implementation of a business
                relationship based exclusively on fully automated processing. -When we use automated
                decisions we are legally required to do this to complete a credit assessment on you during your
                application to the financial services of GUP or to carry out
                anti-fraud checks on the transactions you perform.
            </p>
            <p>
                If you disagree with this practice or such a decision, you are entitled to contest this by contacting us
                at the following email address:&nbsp;<a href={`mailto:${contactsEmail}`}>{contactsEmail}</a>.
            </p>

            <h3>LEGAL BASIS FOR PROCESSING</h3>
            <p>GUP will process your personal data only when we have one of the following grounds:</p>
            <div className="dottedList">
                <p>On the basis of your consent, for example, to send you marketing messages, unless consent will be
                   required by law;</p>
                <p>Where necessary to enter into a contract with you and perform our obligations under that
                   contract;</p>
                <p>Where we have a legitimate interest, subject to such processing not overriding your own rights and
                   freedoms;</p>
                <p>Where we have a legal obligation to collect your personal data or otherwise need your personal data
                   to protect your vital interests or those of another person; or</p>
                <p>Exceptionally when necessary in the public interest, for example, when law enforcement agencies
                   request information to investigate a crime.</p>
            </div>

            <p>For more details, please review the&nbsp;<a href='#comprehensive'> Comprehensive
                                                                                                          Privacy
                                                                                                          Notice</a></p>

            <h3>CHANGES TO OUR PRIVACY NOTICE</h3>
            <p>
                Any changes we may make to our privacy notice in the future will be posted on this page and, where
                appropriate, notified to you by email
                or other means of electronic communication. Please check our privacy notice frequently to see if there
                are any updates or changes.
            </p>

            <h3>CONTACT US</h3>
            <p>At GUP, we welcome all comments, queries, and requests relating to our use of your information.</p>
            <p>
                Our Data Protection Contact person is the person in charge of ensuring our company adheres to this
                privacy notice. This person is also the main contact for our
                Data Protection Supervisory Authority. If you wish to exercise any of your rights or receive further
                information concerning GUP-CH AG (to which this notice applies),
                you should write to our Data Protection contact person via the contact form on
                the&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;page of our website or at the address below:
            </p>
            <p>GUP-CH AG, Attention: Data Protection Contact Person, Chaltenbodenstrasse 16, 8834 Schindellegi,
               Switzerland</p>

            <h1 id="comprehensive">{t(`LegalPages.PrivacyPolicy.LegalComprehensive`)}</h1>
            <h4>Last updated 1 May 2024</h4>

            <h3>Introduction</h3>

            <p>
                Please read the following carefully to understand our views and practices regarding your personal data
                and how we will treat it. GUP is the data
                controller under this Comprehensive Privacy Notice, which sets out the manner in which GUP, as data
                controller, may use your personal data.
            </p>
            <p>
                This Comprehensive Privacy Notice, together with our&nbsp;<RouterLink to="/legal/terms-of-use">Terms of
                                                                                                               Use</RouterLink>&nbsp;governs
                our collection, processing and use of your personal data under
                the applicable data protection laws, including the&nbsp;<a
                href="https://www.fedlex.admin.ch/eli/cc/1993/1945_1945_1945/en"
                target="_blank"
                rel="noreferrer"
            >Swiss Data Protection Act</a>&nbsp; and the EU General Data Protection Regulation 2016/679,
                their successors or implementing texts (together the “DPL”).
            </p>
            <p>
                Personal data has the meaning set forth in the DPL (hereinafter “Personal Data”). This notice covers our
                use of your Personal Data arising from the use of the GUP website (www.globalunitpay.ch)
                as well as registering/subscribing / buying/selling / using our products and services. If you have any
                questions or need any further clarity, please get in touch.
                Contact details are set out below in the&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;section. As used in this Privacy Policy, "GUP", "we", "us" or "our" refers to GUP-CH
                AG, a Swiss financial intermediary,
                registered with the Swiss Companies Register under company number <a
                href={`https://zg.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=${COMPANY_NUMBER}`}
                target="_blank"
                rel="noreferrer"
            >
                {COMPANY_NUMBER}
            </a>, with its registered address at Chaltenbodenstrasse 16, 8834 Schindellegi, Switzerland.
            </p>
            <p>
                References to "you" or "your" in this privacy notice are to the individual who is accessing or applying
                to use the GUP Services (as defined below) either on your own account or on behalf of a business.
                This includes, in relation to a customer or prospective customer of GUP, any sole trader and any
                principals, including the managing and financial directors, any other directors and officers,
                shareholders, partners, and beneficial owners of a customer, as well as any member of staff accessing or
                using the GUP Services on behalf of a customer.
            </p>
            <p>
                This notice shows our commitment to protect your privacy rights and sets out the basis on which any
                Personal Data we collect from you, or that you provide to us,
                will be processed by us. It applies to:
            </p>
            <div className="dottedList">
                <p>the website/portal features and services provided to you when you visit the GUP website</p>
                <p>when you enquire, apply to use and/or use GUP’s services as well as when you request changes to the
                   services you are using;</p>
                <p>your use of software, including terminals, mobile and desktop applications provided by GUP; and</p>
                <p>email, other electronic messages including SMS, telephone, web chat, website/portal, and other
                   communications between you and GUP.</p>
            </div>
            <p>Together, these are all referred to in this notice as "GUP Services".</p>

            <h3>The purpose of this privacy notice </h3>

            <p>GUP is committed to protecting and respecting your privacy. The purpose of this Privacy Policy is to
               describe:</p>
            <div className="dottedList">
                <p>The types of Personal Data we collect and how it may be used;</p>
                <p>Our use of cookies and similar technology;</p>
                <p>How and why we may disclose your Personal Data to third parties;</p>
                <p>The transfer of your Personal Data within and outside of Switzerland and the European Economic Area
                   ("EEA");</p>
                <p>Your statutory rights concerning your Personal Data;</p>
                <p>The security measures we use to protect and prevent the loss, misuse, or alteration of Personal Data;
                   and</p>
                <p>Retention of your Personal Data by GUP.</p>
            </div>

            <h3>Collection and use of Personal Data</h3>
            <span className="paragraphSection">A. Personal Data relating to you</span>
            <p>
                We collect Personal Data to provide you with our Services. When we require certain Personal Data from
                users, it is because we are required by law to collect this information,
                or it is relevant for specified purposes. Any information you provide to us that is not required is
                voluntary. You are free to choose whether to provide us with the types of
                Personal Data requested, but we may not be able to serve you as effectively or offer you all of our
                Services when you do choose not to share certain information with us.
            </p>
            <p>
                For example, we collect Personal Data, which is required under the law to register for an account, add a
                payment method, or execute a transaction.
                We also collect Personal Data when you use or request information about our Services, subscribe to
                marketing communications, request support, complete surveys, or sign up for a GUP event.
                We may also collect Personal Data from you offline, such as when you attend one of our events or when
                you contact customer support.
                We may use this information in combination with other information we collect about you as set forth in
                this Privacy Policy. This may include:
            </p>
            <div className="dottedList">
                <p>Personal Identification Information: Full name, date of birth, age, nationality, gender, signature,
                   utility bills, photographs, phone number, home address, and/or email;</p>
                <p>Account information, such as username, password, account settings and preferences;</p>
                <p>Financial information, such as bank account numbers, bank statements, and trading information;</p>
                <p>Formal Identification Information used for identity verification: Tax ID number, passport number,
                   driver's license details, national identity card details, photograph identification cards, and/or
                   visa information;</p>
                <p>Residence verification information, such as utility bill details or similar information;</p>
                <p>Information regarding the way in which you use our services, such as when you used our services and
                   the specific services used; and</p>
                <p>Information relating to communications with us, whether through the GUP website or via email, over
                   the phone or via any other medium;</p>
                <p>Employment Information: Office location, job title, and/or description of role;</p>
                <p>Transaction Information: Information about the transactions you make on our Services, such as the
                   name of the recipient, your name, the amount, and/or timestamp;</p>
                <p>Online Identifiers: Geo location/tracking details, browser fingerprint, OS, browser name and version,
                   and/or personal IP addresses;</p>
                <p>
                    Collection and Use of Face Data: We collect and use face data, including facial images and biometric
                    identifiers, solely for user verification purposes to enhance platform security,
                    prevent fraud, and comply with legal requirements. Your consent to this data collection is implicit
                    when using our services;
                </p>
                <p>
                    <div>
                        Usage Data: Survey responses, information provided to our support team, public social networking
                        posts, authentication data, security questions, user ID, click-stream data and other data
                        collected via cookies
                        and similar technologies. Please also read our&nbsp;<RouterLink to="/legal/cookie-statement">Cookie
                                                                                                                     Statement</RouterLink>&nbsp;for
                        more information; and/or
                    </div>
                </p>
                <p>Any other information that you or your customer provide to us.</p>
            </div>
            <p>
                We also automatically collect certain computer, device, and browsing information when you access the GUP
                website or use GUP Services. This information is aggregated to provide statistical data about our users'
                browsing actions and patterns and does not personally identify individuals. This information may
                include:
            </p>
            <div className="dottedList">
                <p>
                    Computer or mobile device information, including IP address, operating system, network system,
                    browser type and settings. This information may be directly obtained by GUP or through
                    third-party services such as Google Analytics and other tracking tools. This service usage data
                    helps us our systems to ensure that our interface is accessible
                    for users across all platforms and can aid during criminal investigations; and/or
                </p>
                <p>Website usage information.</p>
            </div>
            <p>
                We also collect information through our applications. If you download or use mobile or desktop
                applications provided by GUP, we may receive information about your location and your device
                or the service you are using (including where a payment transaction takes place). Some devices allow
                applications to access real-time location-based information (for example, GPS).
                Our mobile and desktop apps may collect such information from your mobile device or your computer at any
                time while you download or use our apps if your device has real-time location data access enabled.
                Where required, we will always seek to notify you if it is our intent to collect real-time location
                information and, also where required by law, obtain your consent. We may use such information for
                regulatory purposes,
                our own due diligence checks, to better understand transaction patterns and to optimize your experience.
            </p>
            <p>
                We may receive information about you from other sources and add it to our account information. For
                example, we work closely with, and receive information from third parties like business partners,
                financial institutions, merchants, subcontractors in technical, payment, and delivery services,
                advertising networks, analytics providers, search information providers, credit reference and fraud
                prevention agencies.
                Credit reference and fraud prevention agency use is further explained below. We may also review public
                comments and opinions made on social networking sites (e.g. Meta and X (formerly Twitter))
                to better understand our customers and our provision and development of the GUP Services.
            </p>
            <p>Finally, we may collect Personal Data from third-party partners and public sources, which include:</p>
            <div className="dottedList">
                <p>Reputational information;</p>
                <p>Financial information;</p>
                <p>Business activities of corporate customers.</p>
            </div>
            <p>
                We need to collect certain types of information for compliance with legal requirements relating to our
                anti-fraud/anti-money laundering/counter financing of terrorism/know your customer obligations.
                If this information is not provided, we will not be able to provide a service for you. Your Personal
                Data may also be processed if it is necessary on reasonable request by a law enforcement or
                regulatory authority, body or agency or in the defense of legal claims. We will not delete Personal Data
                if relevant to an investigation or a dispute.
                It will continue to be stored until those issues are fully resolved. We may perform behavioral tracking
                of a customer's activities on our
                GUP website or across different websites or allow third-party data collection through our GUP Service.
            </p>
            <p>
                It is important to note that the Personal Data we collect on you when you create an account will be
                retained for the mandatory retention period set forth by applicable law and as necessary
                for us to maintain exhaustive documentation of our operations as required from us as regulated financial
                sector professionals, even if your account has not been successfully activated
                (e.g., if account verification has not been completed) or no transaction has been made using it.
            </p>
            <p>
                Our privacy notice has incorporated elements from the{' '}
                <a
                    href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri%3DCELEX%253A32016R0679"
                    target="_blank"
                    rel="noreferrer"
                >
                    General Data Protection Regulation (GDPR)
                </a>{' '}
                as we act in accordance to its Personal Data processing rules within the European Economic Area (EEA).
                For individuals who reside in the European Economic Area,
                (including Switzerland (collectively "EEA Residents"), pursuant to Article 6 of the EU General Data
                Protection Regulation (GDPR) or any equivalent legislation
                (including but not limited to Swiss data protection laws and regulations, collectively "EEA Data
                Protection Law"), we process this Personal Data to comply with our legal obligations
                as they are applicable.
            </p>
            <span className="paragraphSection">B. Use of Cookies and Similar Technology</span>
            <p>
                Various types of cookies and web analytics services are used on the GUP websites. For more information
                on the use of cookies and web analytics we use, and how we use them,
                consult see our&nbsp;<RouterLink to="/legal/cookie-statement">Cookie Statement</RouterLink>.
            </p>

            <span className="paragraphSection">C. How We Use Your Personal Data</span>
            <p>
                We collect and use your information and Personal Data for a variety of reasons. We need some information
                and Personal Data to enter into and perform our contract – for example your contact
                and payment details. Some processing of your Personal Data is required by law due to our anti-fraud
                screening obligations or in the public interest, such as making sure we properly verify our customers'
                identities.
            </p>
            <p>
                Some information and Personal Data is processed because you've given your consent to that, which can be
                withdrawn in your account preferences and settings. Other information and Personal Data
                we collect and use because we have legitimate business interests to so, having taken into account your
                rights, interests and freedoms.
            </p>
            <p>In accordance with this intended use we may use your Personal Data to:</p>
            <div className="dottedList">
                <p>
                    Create and administer your GUP account and generally for accounting, billing, maintenance of legal
                    documentation and claim and dispute management. Related processing operations are
                    necessary for the performance of a contract with you (or to take steps at your request prior to
                    entering into a contract), and for compliance with legal obligations to which we are
                    subject;
                </p>
                <p>Process your GUP transactions. Related processing operations are necessary for the performance of a
                   contract with you and for compliance with legal obligations to which we are subject;</p>
                <p>
                    <div>
                        Verify your identity in accordance with applicable know-your-customer, money laundering and
                        other financial sector legislation or regulations, including as required for compliance with the
                        GUP&nbsp;<RouterLink to="/legal/aml-ctf-policy">Anti Money Laundering ("AML")/Counter Terrorist
                                                                   Financing ("CTF") Policy</RouterLink>, as well as
                        address other law enforcement needs as more fully described
                        in our&nbsp;<RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>&nbsp;, and generally
                        as required for compliance with legislation and regulations applicable to GUP. GUP utilizes and
                        thus shares information
                        with a third-party AML verification tool in addition to its internal identification screening
                        procedures.
                    </div>
                </p>
                <p>Personalize your GUP Services experience. Related processing operations are necessary for purposes of
                   our legitimate interests (that is, improving our services)</p>
                <p>Analyze GUP website usage and improve our website and website offerings. Related processing
                   operations are necessary for purposes of our legitimate interests (that is, improving and promoting
                   our services);</p>
                <p>
                    Help us respond to your customer service requests and support needs. Related processing operations
                    are necessary for the performance of a contract with you, and for purposes of our legitimate
                    interests (that is, improving our services and offering you the best experience); and/or
                </p>
                <p>Contact you about GUP Services. The email address you provide may be used to communicate information
                   and updates related to your use of the GUP Services.</p>
            </div>

            <span className="paragraphSection">Automated Decisions Making</span>
            <p>
                Automated decisions mean that a decision concerning you is made automatically on the basis of a computer
                determination (using software algorithms), without our human review. As a rule, we do not make
                any decisions on the establishment and implementation of a business relationship based exclusively on
                fully automated processing. However, we may use automated decision tools and procedures on certain
                matters where
                we are legally required to do so. For example, we may do this to decide whether we can provide our
                services to you based on a credit check/AML risk or anti-fraud profiling. Depending on the outcome of
                the credit
                check/risk profiling, a decision will be reached automatically as to whether we are able to provide
                products or services to you based on your creditworthiness.
            </p>
            <p>If you disagree with the decision, you are entitled to contest this by contacting us at the following
               email address: {contactsEmail}</p>

            <span className="paragraphSection">D. Marketing</span>
            <p>
                We may also occasionally communicate company news, updates, promotions and related information relating
                to similar products and services provided by GUP. We may also administer a contest,
                promotion, survey or other site features as will be more explained on the website. We shall only do this
                where you have given us your consent or otherwise where we are permitted to do so under the
                DPL in pursuit of our legitimate interests (that is, promoting our services).
            </p>
            <p>We may share Personal Data with third parties to help us with our marketing and promotional projects, or
               sending marketing communications.</p>
            <p>
                If you want to opt out of receiving promotional and marketing emails, text messages, post and other
                forms of communications from us (or our promotional partners) in relation to which you might
                receive in accordance with this section, you can best opt out by using one of the following ways:
            </p>
            <div className="dottedList">
                <p>Log into your account and update your profile.</p>
                <p>Click "unsubscribe" at the bottom of an email we sent you.</p>
                <p>contact us at {contactsEmail} to opt-out.</p>
            </div>
            <p>
                If you do opt out of receiving promotional and marketing messages, we can still contact you regarding
                our business relationship with you, such as account status and activity updates,
                survey requests in respect of products and services we have provided to you after you reserve from us,
                reservation confirmations or respond to your inquiries or complaints, and similar communications.
            </p>

            <h3>DISCLOSING AND TRANSFERRING PERSONAL DATA</h3>
            <p>We may disclose your Personal Data to third parties and legal and regulatory authorities and transfer
               your Personal Data outside Switzerland and the EEA, as described below.</p>
            <span className="paragraphSection">A. Disclosures to Third Parties</span>
            <p>There are certain circumstances where we may transfer your Personal Data to employees, contractors, and
               to other parties as follows:</p>
            <div className="dottedList">
                <p>
                    We may share information and Personal Data about you with other members of our group of companies so
                    we can provide the best service across our group.
                    They are bound to keep your information in accordance with this Privacy Policy;
                </p>
                <p>
                    We may also share your information and Personal Data with certain contractors or service providers.
                    They may process your Personal Data for us, for example, if we use a marketing agency.
                    Other recipients/service providers include advertising agencies, IT specialists, database providers,
                    backup and disaster recovery specialists, email providers, or outsourced call centers.
                    Our suppliers and service providers will be required to meet our standards on processing information
                    and security. The information we provide them,
                    including your information and Personal Data, will only be provided in connection with the
                    performance of their function;
                </p>
                <p>
                    We may also share your information and Personal Data with certain other third parties. We will do
                    this either when we receive your consent or because we need them to see your information to provide
                    products
                    or services to you. These include credit reference agencies, anti-fraud databases, screening
                    agencies and other partners we do business with.
                </p>
                <p>
                    We may share your information and Personal Data to improve and develop our business, including,
                    without limitation to optimize our websites/portals, products and services. This may include using
                    the
                    information and Personal Data you insert into forms but do not submit to us, for example, by using
                    that Personal Data to optimize our website(s) and contacting you for customer services purposes in
                    relation to that form. We can also use your Personal Data to develop and test new products and
                    services.
                </p>
            </div>
            <p>Your Personal Data may be transferred to other third-party organizations in certain scenarios:</p>
            <div className="dottedList">
                <p>If we're discussing selling or transferring part or all of our business – the information and
                   Personal Data may be transferred to prospective purchasers under suitable terms as to
                   confidentiality;</p>
                <p>If we are reorganized or sold, information and Personal Data may be transferred to a buyer who can
                   continue to provide services to you;</p>
                <p>If we're required to by law, or under any regulatory code or practice we follow, or if we are asked
                   by any public or regulatory authority – for example, the Police;</p>
                <p>If we are defending a legal claim, your information and Personal Data may be transferred as required
                   in connection with defending such claim.</p>
                <p>To comply with local and national laws;</p>
                <p>
                    To send marketing messages, to provide you with the information on products and services you have
                    requested or we think may be of interest to you; to obtain your views on our goods,
                    services and our website(s); in respect of marketing, market research and similar activities, we may
                    use your Personal Data for such purposes whether or not you are accepted as
                    or continue to receive GUP Services. If you no longer wish to receive marketing or promotional
                    information from GUP, you can always stop it;
                </p>
                <p>
                    <div>
                        To manage and mitigate our credit risks. if you apply for one of our financial products we will
                        assess your financial position (and / or of your business), to the extent
                        this is provided for in the applicable&nbsp;<RouterLink to="/legal/terms-of-use">Terms of
                                                                                                         Use</RouterLink>.
                        This credit check will also affect any linked parties such as directors, shareholders and
                        principals.
                        We can do so by receiving and sharing information and Personal Data from and with credit
                        reference agencies and fraud prevention agencies. This credit check will affect also anyone with
                        whom
                        you have a joint account or similar financial association. If it is a joint application, and
                        such link does not already exist, then one may be created. These links will remain until you
                        file
                        a "notice of disassociation" at the credit reference agencies. For your information, the
                        agencies will record our enquiries which may be seen by other companies who make their own
                        credit enquiries;
                        and a "footprint" may be placed on your credit file, whether or not you are accepted as our
                        customer. If you are a director, we will seek confirmation from credit reference agencies that
                        the residential
                        address that you provide is the same as that held by the relevant companies' registry (where
                        applicable). In addition, where you take services from us, we will give information on how you
                        manage your
                        accounts to the credit reference agencies. If you do not repay any monies in full and on time,
                        credit reference agencies will record the outstanding debt and may share this information and
                        Personal Data with other
                        organizations that perform checks similar to ours. Records remain on file at such agencies for 6
                        years after they are closed, whether settled by you or defaulted. If you would like further
                        information
                        on our use of credit reference agencies, please&nbsp;<a
                        href={contactsURL}
                        target="_blank"
                        rel="noreferrer"
                    >Contact Us</a>;
                    </div>
                </p>
                <p>
                    To prevent, detect and prosecute fraud or crime, GUP may participate in anti-fraud initiatives,
                    which involve assessing you (and/or your customers) and monitoring your transactions and/or
                    locations,
                    to detect patterns requiring investigations or otherwise profile and assess the likelihood of fraud
                    occurring. We can do so utilizing products and services from third parties. Besides, if you give us
                    false or inaccurate information about you, or we identify or suspect a fraud or a crime, we may pass
                    your information and Personal Data to fraud prevention agencies and to law enforcement agencies,
                    and we may decide to take legal action against you;
                </p>
                <p>To prevent or mitigate information security risk;</p>
                <p>
                    To manage and enforce our rights, terms of use or any other contracts with you (and/or your
                    business), including to manage any circumstances where transactions, rewards or points are disputed;
                    manage, investigate and resolve complaints; or recover debt or in relation to your insolvency;
                </p>
            </div>
            <p>Your Personal Data may be shared if it is made anonymous and aggregated, as in such circumstances, the
               information will cease to be Personal Data.</p>
            <p>Your information will not be sold, exchanged, or shared with any third parties without your consent,
               except to provide GUP Services or as required by law.</p>
            <p>
                The third-party service providers of GUP are contractually bound to protect and use such information
                only for the purposes for which it was disclosed, except as otherwise required or permitted by law.
                We ensure that such third parties will be bound by terms complying with DPL.
            </p>
            <p>Specifically, Your Personal Data may be transferred to the following third-party organizations in certain
               scenarios:</p>
            <p>
                When you apply for a prepaid card through the GUP platform, your Personal Data is shared with UAB
                Creditco for the approval process. UAB Creditco is an official Lithuanian representative of Paynovate
                SA.
                UAB Creditco processes and stores data in ISO 27001-certified data centers worldwide.{' '}
                <a
                    href="https://uabcreditco.lt/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more about how UAB Creditco processes data
                </a>
                .
            </p>
            <p>
                When you apply for an IBAN/bank account the GUP platform, your personal KYC data is shared with the
                relevant bank/service provider,
                depending on which jurisdiction/bank you choose for the approval process.
            </p>
            <span className="paragraphSection">B. Disclosures to Legal Authorities</span>
            <p>
                We may be required by law to pass information and Personal Data about you to regulatory authorities and
                law enforcement bodies worldwide, or we may otherwise determine that it is appropriate or necessary to
                do so.
                Such disclosures may also include requests from governmental or public authorities, or with commercial
                organizations with whom you may have had dealings and whom are seeking to mitigate fraud risk,
                or for the purposes of litigation or legal process, national security or where we deem it in the
                national or public interest or otherwise lawful to do so. As such, we may share your Personal Data
                with law enforcement, data protection authorities, government officials, and other authorities when:
            </p>
            <div className="dottedList">
                <p>Compelled by court order, or other legal procedure.</p>
                <p>Disclosure is necessary to report suspected illegal activity.</p>
                <p>Disclosure is necessary to investigate violations of this Privacy Policy or our&nbsp;
                    <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>.</p>
            </div>

            <span className="paragraphSection">C. International Transfers of Personal Data</span>
            <p>
                We store and process your Personal Data in data centers around the world, wherever GUP facilities or
                service providers are located. As such, we may transfer your Personal Data outside of Switzerland
                and the European Union. Some of the countries to which your Personal Data are located outside the EU do
                not benefit from an adequacy decision issued by the EU Commission regarding protection
                afforded to Personal Data in that country. Details of these specific countries can be found here:{' '}
                <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en
                </a>
                . Such transfers are undertaken in accordance with our legal and regulatory obligations and appropriate
                safeguards under DPL will be implemented, such as standard data protection clauses with data
                recipients or processors approved by competent authorities. A copy may be requested at the address set
                out in the&nbsp;
                <a
                    href={contactsURL}
                    target="_blank"
                    rel="noreferrer"
                >Contact Us</a>&nbsp;section.
            </p>

            <h3>YOUR STATUTORY RIGHTS</h3>
            <p>You have certain rights concerning your Personal Data under DPL as mentioned below, and can exercise them
               by emailing us at {contactsEmail}.</p>
            <div className="dottedList">
                <p>
                    Right to access and information: you are entitled to ask us if we are processing your Personal Data
                    and, if we are, you can request information about and access to your Personal Data.
                    This enables you to receive information about the type and volume of the Personal Data we hold about
                    you and certain other information about it to check that we are lawfully processing it.
                    We process a large quantity of information and Personal Data and can thus request, in accordance
                    with the DPL, that before the information and Personal Data is delivered, you identify yourself
                    by ID card/passport copy and specify the Personal Data and/or processing activities to which your
                    request relates.
                </p>
                <p>Right of correction: you are entitled to request that any incomplete or inaccurate Personal Data we
                   hold about you is corrected.</p>
                <p>
                    Right of erasure: you are entitled to ask us to delete or remove Personal Data in certain
                    circumstances. There are also certain exceptions where we may refuse a request for erasure,
                    for example, where the Personal Data is required for compliance with law, statutory retention
                    requirements or in connection with claims
                </p>
                <p>Right to restriction: you are entitled to ask us to suspend the processing of certain of your
                   Personal Data about you, for example, if you want us to establish its accuracy or the reason for
                   processing it.</p>
                <p>Transfer right: you may request the transfer of certain of your Personal Data to another party.</p>
                <p>
                    Right of objection: where we are processing your Personal Data based on legitimate interests (or
                    those of a third party) you may challenge this. However, we may be entitled to continue processing
                    your
                    Personal Data based on our legitimate interests or where this is relevant to legal claims. You also
                    have the right to object where we are processing your Personal Data for direct marketing purposes.
                </p>
                <p>Automated decisions: you may contest any automated decision (where this relates to automated
                   processing without human intervention) made about you where this has a legal or similar significant
                   effect and ask for it to be reconsidered.</p>
            </div>
            <p>
                Please consider that depending on the country from where you use the GUP Services, not all of the above
                rights may be available to you. Also, there might be cases where these rights cannot be enforced: for
                example, you cannot object to
                us using your Personal Data when it is required by the law, or to manage a complaint; similarly, you
                cannot ask us to delete your Personal Data if you used our GUP Services (during legal retention period)
                or want to
                continue using the GUP Services.
            </p>

            <h3>SECURITY OF PERSONAL DATA</h3>
            <p>
                We use a variety of security measures to ensure the confidentiality of your Personal Data, and to
                protect your Personal Data from loss, theft, unauthorized access, misuse, alteration, or destruction.
                These security measures include, but are not limited to:
            </p>
            <div className="dottedList">
                <p>Password-protected directories and databases;</p>
                <p>Secure Sockets Layered (SSL) technology to ensure that your information is fully encrypted and sent
                   across the Internet securely;</p>
                <p>PCI Scanning to actively protect our servers from hackers and other vulnerabilities.</p>
            </div>
            <p>
                All financially sensitive and/or credit information is transmitted via SSL technology and encrypted in
                our database. Only authorized GUP personnel are permitted access to your Personal Data,
                and this personnel are required to treat the information as highly confidential. The security measures
                will be reviewed regularly in light of new and relevant legal and technical developments.
            </p>
            <p>
                We do not ask for financial or payment information, such as your credit card number, passcode, account
                number, or pin number, in an email, text, or any other communication that we send to you.
                Please always check that any website on which you are asked for financial or payment information in
                relation to our reservations or services is operated by GUP. If you do receive a suspicious request,
                do not provide your information and report it by contacting one of our member service representatives as
                set in this Privacy Policy.
            </p>
            <p>
                You are responsible for keeping your account passcode, membership numbers, and pin numbers safe and
                secure. Do not share those with anyone. If there is an unauthorized use or any other breach of security
                involving your information, you must notify us below as soon as possible.
            </p>
            <p>
                Please note the transmission of information via the Internet is not completely secure. Although we will
                do our best to protect your Personal Data, we cannot guarantee the security of your
                information transmitted to our site, unless you are communicating with us through a secure channel that
                we have provided. Once we have received your information, we will use strict procedures
                and security features to try to prevent unauthorized access.
            </p>

            <h3>RETENTION OF PERSONAL DATA</h3>
            <p>
                We retain Personal Data for as long as necessary to fulfill purposes described in this Privacy Policy,
                subject to our own legal and regulatory obligations. The criteria we may use to determine
                the retention period for certain categories of data includes:
            </p>
            <div className="dottedList">
                <p>How long you have been a GUP member;</p>
                <p>Whether there are contractual or legal obligations that exist that require us to retain the data for
                   a certain period of time;</p>
                <p>Whether there is any ongoing legal or financial claim that relates to your relationship with us;</p>
                <p>Whether any applicable law, statute, or regulation allows for a specific retention period; and</p>
                <p>What the expectation for retention was at the time the data was provided to us.</p>
            </div>
            <p>
                While you use the GUP Services, we will retain your Personal Data as long as necessary to provide you
                with the services of your choice. That would generally mean we retain your Personal Data
                as long as you are our customer and for a period of time afterward. When our relationship with you ends,
                we still need to retain certain of your Personal Data for a time that depends on the
                legal and regulatory requirements of the country where you are located. For example, we will retain your
                Personal Data for the time allowed by the local laws to start a legal claim
                (so-called "statute of limitation"), or for as long as we are ordered pursuant to an order from the
                courts, or by law enforcement agencies or our regulators. In accordance with our
                record-keeping obligations, we will retain Account and other Personal Data for at least five years (and
                some up to ten years, as required by applicable law) after an Account is closed.
            </p>

            <h3>Face Personal Data Store for App</h3>
            <span className="paragraphSection">A. Collection and Use of Face Personal Data</span>
            <p>Reasons for Storing Face Personal Data: We collect and store face Personal Data for the sole purpose of
               enabling specific features and functionalities of our App, such as facial recognition for user
               authentication and security.</p>
            <p>
                Length of Time Face Personal Data is Stored: We do not store face personal data indefinitely. We retain
                face personal data only for the duration necessary to fulfill the purposes for which it was collected.
                This time frame may vary depending on the specific features used and applicable legal requirements.
            </p>

            <span className="paragraphSection">B. Sharing of Face Personal Data</span>
            <p>We do not share your face Personal Data with any third parties except in the following circumstances:</p>
            <div className="dottedList">
                <p>When required by law or to protect our legal rights.</p>
                <p>To affiliated service providers who assist us in providing the App's services.</p>
            </div>

            <span className="paragraphSection">C. Security</span>
            <p>
                We take reasonable measures to protect the security and confidentiality of the data collected, including
                face Personal Data. We use industry-standard practices to safeguard your Personal Data and other
                information. However,
                no method of transmission over the internet or electronic storage is entirely secure, and we cannot
                guarantee its absolute security.
            </p>

            <span className="paragraphSection">D. Contact Information</span>
            <p>
                If you have any questions, concerns, or requests regarding this Privacy Policy or the information and
                Personal Data we hold about you, please contact us at <a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >
                {contactsURL}
            </a>.
            </p>
            <span className="paragraphSection">E. Changes to this Privacy Policy</span>
            <p>
                We may update this Privacy Policy to reflect changes to our information practices. If we make any
                material changes, we will notify you by posting a notice within the App or by
                email (sent to the email address specified in your account). Please periodically review this page for
                the latest information on our privacy practices.
            </p>
            <p>By using the App, you agree to the collection and use of information in accordance with this Privacy
               Policy.</p>
            <b>This Privacy Policy is effective as of the date specified above and will remain in effect until any
               revisions are made.</b>

            <h3>UPDATES TO THIS PRIVACY POLICY</h3>
            <p>
                This Privacy Policy was last revised effective as of the notice at the beginning of this policy page. We
                may change this Privacy Policy from time to time, so it is advisable to review it frequently.
                Changes to this Privacy Policy will be announced on our website or through similar means for a
                reasonable length of time prior to and following the change taking effect.
            </p>

            <h3>Definitions</h3>
            <p>
                Account. The contractual arrangement wherein a GUP member has accepted our&nbsp;
                <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>&nbsp;and&nbsp;
                <RouterLink to="#">Privacy Policy</RouterLink>&nbsp;, and received approval to use the GUP Services,
                including the purchase and sale of Bitcoins and to perform associated Transactions;
            </p>
            <p>
                GUP Platform. Hardware and software technologies used by GUP to provide the GUP Service as set out in
                our&nbsp;<RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>;
            </p>
            <p>
                DPL means data protection law applicable to GUP, including the{' '}
                <a
                    href="https://www.fedlex.admin.ch/eli/cc/1993/1945_1945_1945/en"
                    target="_blank"
                    rel="noreferrer"
                >
                    Swiss Data Protection Act
                </a>{' '}
                and the EU General Data Protection Regulation 2016/679, their successors or implementing texts as well
                as equivalent legislation, which is applied to the processing of Personal Data by GUP.
            </p>
            <p>
                Personal Data. Information that identifies an individual, such as name, address, email address, trading
                information, and banking details. Personal Data does not
                include anonymised and/or aggregated data that does not identify a specific user;
            </p>
            <p>Service(s). The technological platform, functional rules, and market managed by GUP to permit Sellers and
               Buyers to perform purchase and sale transactions of Bitcoins and/or other Cryptocurrencies.</p>
            <p>Transaction. Includes the following:</p>
            <div className="dottedList">
                <p>The action between the Member to Buy and/or Sell cryptocurrencies through the Service for currencies
                   at the market rate.</p>
                <p>The transfer of cryptocurrencies between Members ("Transfer Transaction");</p>
                <p>The transfer of currencies among Members ("Currency Transfer Transaction"); and</p>
                <p>GUP may not offer all these transaction types at this time.</p>
            </div>

            <h3>Global Notice</h3>
            <p>
                This notice is global in scope, but is not intended to override any legal rights or prohibitions in any
                territory where such rights or prohibitions prevail. In such event, the rights and obligations
                set out in this notice will apply, subject only to amendment under any applicable local law having
                precedence.
            </p>

            <h3>Contact Us</h3>
            <p>
                If you have questions or concerns regarding this Privacy Policy, or if you have a complaint,
                please&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;
                via the contact form on the&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;page of our website or by writing to us at the following address:
            </p>
            <p>GUP-CH AG</p>
            <p>Attn: Data Protection Contact Person</p>
            <p>Chaltenbodenstrasse 16</p>
            <p>8834 Schindellegi</p>
            <p>Switzerland</p>

            <h3>Complaints</h3>
            <p>
                You can also complain about our processing of your Personal Data to the relevant data protection
                authority. You can complain in the EU member state where you live or work or in
                the place where the alleged breach of data protection law has taken place. In Switzerland,{' '}
                <a
                    href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    The Federal Data Protection and Information Commissioner (FDPIC)
                </a>{' '}
                is the competent authority for data processing by federal bodies and private persons, including
                enterprises. As far as data processing in Europe, the competent body is the{' '}
                <a
                    href="https://edps.europa.eu/data-protection_en"
                    target="_blank"
                    rel="noreferrer"
                >
                    European Data protection Supervisor
                </a>
                . As far as data processing by cantonal or communal authorities is concerned, the supervision is up to
                the cantonal and communal data protection commissioners.
                You can find all contact information&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >here</a>.
            </p>
        </div>
    )
}
