import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from "styled-components";
import { colors } from '../../../helpers/consts'

import { apear } from '../../../App.styled'

const CustomSelectMenu = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: stretch;
  z-index: 30;
  background-color: ${colors.lightBlue};
  top: 3.2rem;
  width: 16rem;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
  overflow-y: auto;
  max-width: 26rem;
  padding: 0.8rem;
  animation: ${apear} 0.2s ease-out forwards;

  @media (min-width: 30em) {
    top: -0.6rem;
    right: -15.8rem;
  }

  @media (min-width: 56em) {
    top: 4.4rem;
    left: 0;
  }
`;

const MenuItem = styled.button.attrs({
  type: 'button'
})`
color: ${colors.white};
padding: 1rem;
transition: color 0.3s ease-out, box-shadow 0.2s ease-out;
font-size: 1.3rem;
font-weight: 500;

&:hover,
&:focus {
  color: ${colors.orange};

 & + ${CustomSelectMenu} {
    display: flex;
  }
}

  @media (min-width: 56em) {
    padding: 1.4rem 1.5rem;
  }
`;

const NavItemContainer = styled.div`
  position: relative;

  &:hover,
  &:focus {
    ${CustomSelectMenu} {
      display: flex;
    }
  }
`;

const LinkItem = styled(Link)`
   display: flex;
   align-items: center;
   flex-wrap: nowrap;
   font-size: 1.3rem;
   color: ${colors.black};
   padding: 0.8rem;
   transition: color 0.3s ease-out;

   &:hover,
   &:focus {
    color: ${colors.orange};

    ${CustomSelectMenu} {
      display: flex;
    }
   }
`;

export default function WithSubMenu({children, subMenu}) {
  const { t } = useTranslation()

  return (
    <NavItemContainer>
      <MenuItem>{children}</MenuItem>

      <CustomSelectMenu>
        {subMenu.map(({ path, label, key }) => (
          <LinkItem key={key} to={path}>
            {t(label)}
          </LinkItem>
        ))}
      </CustomSelectMenu>
    </NavItemContainer>
  );
}
