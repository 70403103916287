import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { cookiesLink, colors } from '../helpers/consts'
import { Button } from '../App.styled'

const Wrapper = styled.div`
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  background-color: ${colors.darkBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 24px rgb(0 0 0 / 30%);

  p, a {
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 500;

    @media (min-width: 30em) {
      font-size: 1.4rem;
    }
  }

  p {
    margin-right: 1rem;
  }

  a {
    text-decoration: underline;
    transition: color 0.2s ease-out;
     
    :hover {
      color: ${colors.orange};
    }
  }

  @media (min-width: 30em) {
    padding: 1.5rem 2rem;
  }
`

const CookiesPopup = (props) => {
  const { t } = useTranslation()
  const [isCookieOpen, setIsCookieOpen] = useState(false)
  const accept = () => {
    localStorage.setItem('cookieAccept', true)
    setIsCookieOpen(false)
  }

  useEffect(() =>{
    const isCookieAccepted = localStorage.getItem('cookieAccept')

    if (!isCookieAccepted) {
      setIsCookieOpen(true)
    }
  }, [])


  return <>
    {props.children}

    {isCookieOpen && (
      <Wrapper>
        <p>{t('GLOBAL.COOKIES_DESC')} <a 
          href={cookiesLink} 
          target="_blank" rel="noreferrer">
            {t('GLOBAL.COOKIES_INFO')}
        </a></p>

        <Button onClick={accept}>{t('GLOBAL.COOKIES_BUTTON')}</Button>
      </Wrapper>
    )}
  </>
}

export default CookiesPopup
