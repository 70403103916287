import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import styled from "styled-components";
import routes from "../../../routes/routes";
import { colors } from '../../../helpers/consts'
import WithSubMenu from "./WithSubMenu";

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-left: 1.4rem;
  max-width: fit-content;

  @media (min-width: 56em) {
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 4.4rem;
    max-height: 4.4rem;
    max-width: 100%;
  }
`;

const NavItem = styled(NavLink)`
  color: ${colors.white};
  padding: 1rem;
  transition: color 0.3s ease-out, box-shadow 0.2s ease-out;
  font-size: 1.4rem;
  font-weight: 500;

  &:hover,
  &:focus {
    color: ${colors.orange};
  }

  @media (min-width: 56em) {
    padding: 1.1rem 1.5rem;
  }
`;

export default function Navigation() {
  const { t } = useTranslation()

  return (
    <Nav>
      {routes
          .filter(({ mainNav }) => mainNav)
          .map(({ path, key, label, withSubMenu, subMenu }) => {
            if (withSubMenu) {
              return <WithSubMenu style={({ isActive }) =>
              isActive ? {
                color: "#f6b32f",
              } : undefined
            }
                key={key}
                subMenu={subMenu}
              >{t(label)}</WithSubMenu>;
            }
           return <NavItem
             style={({ isActive }) =>
              isActive ? {
                color: "#f6b32f",
              } : undefined
            }
              key={key}
              to={path}
            >
              {t(label)}
            </NavItem>}
          )}
    </Nav>
  );
}
