import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const Guardian = styled.div`
  height: 0.1rem;
  position: absolute;
  top: 0;
  left: 0;
`;

function IntObserver(observeElem, func) {
  const onEntry = (entry) => {
    if (entry[0].isIntersecting) {
      func("1");
    }
    if (!entry[0].isIntersecting) {
      func("2");
    }
    return;
  };

  const options = {
    rootMargin: "150px 0px 0px 0px",
    threshold: 1,
  };

  const observer = new IntersectionObserver(onEntry, options);

  observer.observe(observeElem);
}

export default function IntObsElement({ func }) {
  const guardian = useRef(null);

  useEffect(() => IntObserver(guardian.current, func), [func]);

  return <Guardian ref={guardian} />;
}
