import {Suspense} from "react";
import {Route, Navigate, Routes} from "react-router-dom";

import './helpers/i18next'
import routes from "./routes/routes";
import routesPaths from "./routes/routesPaths";
import Layout from "./components/Layout";
import Preloader from "./components/Preloader";
import {LegalPage} from "./pages/LegalPage";
import {TermOfUse} from "./components/LegalPage/SubPages/TermOfUse-component";
import {ComprehensivePrivacyPolicy} from "./components/LegalPage/SubPages/ComprehensivePrivacyPolicy-component";
import {PrivacyPolicy} from "./components/LegalPage/SubPages/PrivacyPolicy-component";
import {RiskWarning} from "./components/LegalPage/SubPages/RiskWarning-component";
import {RegulatorySupervision} from "./components/LegalPage/SubPages/RegulatorySupervision-component";
import {Impressum} from "./components/LegalPage/SubPages/Impressum-component";
import {CookieStatement} from "./components/LegalPage/SubPages/CookieStatement-component";
import {AMLCTFPolicy} from "./components/LegalPage/SubPages/AMLCTFPolicy-component";
import {Trademarks} from "./components/LegalPage/SubPages/Trademarks-component";

export default function App() {
    return (
        <Layout>
            <Suspense fallback={<Preloader />}>
                <Routes>
                    {routes.map((route) => (
                        <Route key={route.path} {...route} />
                    ))}
                    <Route
                        path='/legal'
                        element={<LegalPage />}
                    >
                        <Route
                            path="/legal/terms-of-use"
                            element={<TermOfUse />}
                        />
                        <Route
                            path="/legal/privacy-policy"
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path="/legal/comprehensive-privacy-policy"
                            element={<ComprehensivePrivacyPolicy />}
                        />
                        <Route
                            path="/legal/risk-warning"
                            element={<RiskWarning />}
                        />
                        <Route
                            path="/legal/regulatory-supervision"
                            element={<RegulatorySupervision />}
                        />
                        <Route
                            path="/legal/impressum"
                            element={<Impressum />}
                        />
                        <Route
                            path="/legal/cookie-statement"
                            element={<CookieStatement />}
                        />
                        <Route
                            path="/legal/aml-ctf-policy"
                            element={<AMLCTFPolicy />}
                        />
                        <Route
                            path="/legal/trademarks"
                            element={<Trademarks />}
                        />
                        <Route
                            path="/legal"
                            element={<Navigate
                                to='/legal/terms-of-use'
                                replace
                            />}
                        />
                        <Route
                            path='/legal/*'
                            element={<Navigate
                                to='/legal/terms-of-use'
                                replace
                            />}
                        />
                    </Route>
                    <Route
                        path='*'
                        element={<Navigate
                            to={routesPaths.main}
                            replace
                        />}
                    />
                </Routes>
            </Suspense>
        </Layout>
    );
}
