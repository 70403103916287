import styled, { createGlobalStyle, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { colors } from './helpers/consts'

export const AppStyles = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
    font-size: 62.5%;
    font-style: normal;
    font-weight: normal;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    background-color: ${colors.white};
    color: ${colors.darkBlue};
    min-width: 32rem;
    margin: 0;
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
  }

  #root {
    width: 100%;
    min-width: 32rem;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  ul > li {
    list-style: none;
  }

  ol {
    padding-inline-start: 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  svg {
    max-width: 100%;
  }

  tr,
  td,
  th,
  p,
  button,
  ul,
  li,
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }

  input,
  select,
  textarea {
    font-family: 'Montserrat', sans-serif;
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
  }
  button:disabled {
    cursor: initial;
  }

  input:focus,
  input:focus:active,
  textarea:focus,
  textarea:focus:active,
  button:focus,
  button:focus:active,
  a:focus,
  a:focus:active {
    outline: none;
    box-shadow: 0 0 0 0.4rem rgba(13, 110, 253, 0.25);
  }
`;

export const GlobalContentContainer = styled.div`
  margin: 0 auto;
  max-width: 116rem;
  padding: 0 1.2rem;

  @media (min-width: 48em) {
    padding: 0 2rem;
  }
`;

export const MainButton = styled.a.attrs({
  target: "_blank",
})`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({dark}) => dark ? colors.white : colors.black};
  background-color: ${({dark}) => dark ? colors.darkBlue : colors.white};
  border-radius: 3rem;
  border: 1px solid ${({dark}) => dark ? colors.darkBlue : colors.black};
  padding: 1rem 3.3rem;
  max-width: fit-content;
  white-space: nowrap;
  transition: background-color 0.2s ease-out, border 0.2s ease-out, box-shadow 0.2s ease-out;

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    box-shadow: 0px 0px 12px 0px ${colors.orange};
    border: ${colors.orange} 1px solid;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const MainInnerButton = styled(Link)`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${colors.black};
  background-color: ${colors.white};
  border-radius: 3rem;
  border: 1px solid ${colors.black};
  padding: 1rem 3.3rem;
  max-width: fit-content;
  white-space: nowrap;
  transition: background-color 0.2s ease-out, border 0.2s ease-out, box-shadow 0.2s ease-out;

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    box-shadow: 0px 0px 12px 0px ${colors.orange};
    border: ${colors.orange} 1px solid;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Button = styled.button.attrs({
  type: 'button'
})`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${colors.black};
  background-color: ${colors.white};
  border-radius: 3rem;
  padding: 1rem 3.3rem;
  max-width: fit-content;
  white-space: nowrap;
  transition: background-color 0.2s ease-out, border 0.2s ease-out, box-shadow 0.2s ease-out;

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    box-shadow: 0px 0px 12px 0px ${colors.orange};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.6rem;
  ${({restricted}) => restricted && 'max-width: 37rem'};

  @media (min-width: 48em) {
    font-size: 2.3rem;
  }

  @media (min-width: 62em) {
    font-size: 2.7rem;
    text-align: left;
    margin-bottom: 2.4rem;
  }
`

export const SectionText = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  ${({hasMargin}) => hasMargin && 'margin-bottom: 1.6rem;'}
  text-align: center;

  @media (min-width: 48em) {
    font-size: 1.5rem;
  }

  @media (min-width: 62em) {
    font-size: 1.6rem;
    ${({hasMargin}) => hasMargin && 'margin-bottom: 2rem;'}
    text-align: left;
  }
`

export const BigText = styled.p`
  font-size: 2rem;
  line-height: 1.5;
  text-align: center;
  ${({hasMargin}) => hasMargin && 'margin-bottom: 1.6rem;'}
  ${({restricted}) => restricted && '  max-width: 40rem;'}

  @media (min-width: 48em) {
    font-size: 2.4rem;
    text-align: left;
  }

  @media (min-width: 62em) {
    font-size: 2.7rem;
    ${({hasMargin}) => hasMargin && 'margin-bottom: 3rem;'}
  }
`

export const apear = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;
