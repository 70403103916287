import { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import routesPaths from "../../routes/routesPaths";
import { useMediaQuery, useOnClickOutside } from '../../helpers/hooks'
import { siteName } from '../../helpers/consts'

import Navigation from "./components/Navigation";
import UserMenu from "./components/UserMenu";
// import Language from "../Language";

import {GlobalContentContainer} from '../../App.styled'
import {
  Container,
  Content,
  LogoLink, 
  Logo, 
  LogoTitle, 
  Button, 
  ButtonContent, 
  Line, 
  RightContent,
  AdditionalNavContent
} from './styled'

import logoImg from "../../images/UGPay_Logo.svg";

export default function Header({ view }) {
  const location = useLocation()
  const ref = useRef(null)

  const mobile = useMediaQuery("(min-width: 38em)");
  const desktop = useMediaQuery("(min-width: 56em)");

  const [isNavOpen, changeIsNavOpen] = useState(false);
  const [isChangingVision, changeIsChangingVision] = useState(false);
  const [hasOverflow, changeHasOverflow] = useState(false);

  const toggleMenu = useCallback(
    () => {
      let animationControl_1;
      let animationControl_2;
      let animationControl_3;

      animationControl_1 = clearTimeout()
      animationControl_2 = clearTimeout()
      animationControl_3 = clearTimeout()

      if(isNavOpen) {
        changeHasOverflow(false)
        // eslint-disable-next-line no-unused-vars
        animationControl_2 = setTimeout(() => changeIsChangingVision(false), 20) 
        // eslint-disable-next-line no-unused-vars
        return animationControl_1 = setTimeout(() => changeIsNavOpen(false), 400)
      }

      if(!isNavOpen) {
        changeIsNavOpen(true)
        // eslint-disable-next-line no-unused-vars
        animationControl_2 = setTimeout(() => changeIsChangingVision(true), 20) 
        // eslint-disable-next-line no-unused-vars
        return animationControl_3 = setTimeout(() => changeHasOverflow(true), 400)
      }
    },
    [isNavOpen],
  )

  useOnClickOutside(ref, toggleMenu)

  useEffect(() => {
    isNavOpen && desktop && changeIsNavOpen(false);
  }, [desktop, isNavOpen]);

  useEffect(() => {
    if (isNavOpen) {
      toggleMenu()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Container view={view}>
      <GlobalContentContainer>
        <Content view={view}>
          <LogoLink to={routesPaths.main}>
            <Logo src={logoImg} />
            <LogoTitle>{siteName}</LogoTitle>
          </LogoLink>

          {desktop && <Navigation />}

          <RightContent>
            {mobile && <UserMenu view={view} />}
            {/*<Language />*/}
            {!desktop && (
              <Button onClick={toggleMenu}>
                <ButtonContent>
                  <Line />
                  <Line />
                  <Line />
                </ButtonContent>
              </Button>
            )}
          </RightContent>
        </Content>
      </GlobalContentContainer>
      {!desktop && isNavOpen && <AdditionalNavContent hasOverflow={hasOverflow} visible={isChangingVision} ref={ref}>
        <GlobalContentContainer>
          <Navigation />
          {!mobile && <UserMenu view={view} />}
        </GlobalContentContainer>
        </AdditionalNavContent>}
    </Container>
  );
}
