const routesPaths = {
  main: "/",
  about: "/about",
  businessAcc: '/business-account',
  // gupBusiness: '/gup-business',
  // payments: '/payments',
  contacts: "/contacts",
  // help: "/help",
  documents: "/documents",
  // news: "/news",
  privateAcc: "/private-account",
  security: "/security",
  // partners: "/partners",
  commissions: "/commissions",
  cards: "/cards",
};

export default routesPaths;
