export const themeConfig = {
    BRAND_NAME: 'Global Unit Pay',
    COMPANY_NAME: 'GUP-CH AG',
    COMPANY_NUMBER: 'CHE-114.304.932',
    MEMBER_NUMBER: '1274',
    SUPPORT_EMAIL: 'info@globalunitpay.ch',
    CONTACT_STREET: 'Chaltenbodenstrasse 16',
    CONTACT_CITY: 'Schindellegi',
    CONTACT_POSTCODE: '8834',
    CONTACT_COUNTRY: 'Switzerland',
    CONTACT_PHONE: '',
    APP_URL: process.env.REACT_APP_URL,
}

export const contactsURL = 'https://globalunitpay.ch/contacts'
export const contactsEmail = 'compliance@globalunitpay.ch'
