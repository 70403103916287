import {useTranslation} from 'react-i18next'

export const Trademarks = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.TrademarksComp.Legal`)}</h1>
            <p>The following names and designations are trademarks of GUP-CH AG: Global Unit Pay</p>
            <p>
                These names and trademarks, as well as all other trademarks and protected rights mentioned on this
                website are subject unreservedly to the applicable trademark law in each case and are
                not permitted to be used without the express permission of the registered owner. The simple fact that
                the website mentions them does not imply that trademarks are not protected by the
                rights of third parties.
            </p>
            <h3>Copyright Infringement Notification</h3>
            <p>
                To file a copyright infringement notification with us, you will need to send a written communication
                that includes substantially the following (please consult your legal counsel or see
                Section 512(c)(3) of the Digital Millennium Copyright Act to confirm these requirements):
            </p>
            <p>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
               right that is allegedly infringed.</p>
            <p>
                Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works
                at a single online website are covered by a single notification, a
                representative list of such works at that website.
            </p>
            <p>
                Identification of the material that is claimed to be infringing or to be the subject of infringing
                activity and that is to be removed or access to which is to be disabled, and
                information reasonably sufficient to permit the service provider to locate the material. Providing URLs
                in the body of an email is the best way to help us locate content quickly.
            </p>
            <p>
                Information reasonably sufficient to permit the service provider to contact the complaining party, such
                as an address, telephone number, and, if available, an electronic mail address
                at which the complaining party may be contacted.
            </p>
            <p>A statement that the complaining party has a good faith belief that use of the material in the manner
               complained of is not authorized by the copyright owner, its agent, or the law.</p>
            <p>
                A statement that the information in the notification is accurate, and under penalty of perjury, that the
                complaining party is authorized to act on behalf of the owner of an exclusive
                right that is allegedly infringed.
            </p>
            <p>
                Please note that under Section 512(f) any person who knowingly materially misrepresents that material or
                activity is infringing may be subject to liability for damages. Do not make
                false claims!
            </p>
            <p>Please also note that the information provided in this legal notice may be forwarded to the person who
               provided the allegedly infringing content.</p>
            <p>Claimant information will be published on the Ubeea.com website in place of disabled content.</p>
            <h3>Counter-Notification</h3>
            <p>
                Please note that under Section 512(f) of the Copyright Act, any person who knowingly materially
                misrepresents that material or activity was removed or disabled by mistake or
                misidentification may be subject to liability. Please also be advised that we enforce a policy that
                provides for the termination in appropriate circumstances of subscribers who are
                repeat infringers.
            </p>
            <p>Contact GUP to submit a claim or counter claim:</p>
            <p>GUP-CH AG, Chaltenbodenstrasse 16, 8834 Schindellegi, Switzerland.</p>
        </div>
    )
}
