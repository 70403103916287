import styled from "styled-components";
import { useTranslation } from 'react-i18next'
import { signLink, loginLink, colors } from '../../../helpers/consts'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  flex-wrap: wrap;
  margin: 0.6rem 0 0.6rem 1.6rem;

  @media (min-width: 38em) {
    flex-wrap: nowrap;
    margin: 0 0 0 1.6rem;
  }
`;

const LinkRegistration = styled.a.attrs({
  target: '_blank'
})`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${colors.darkBlue};
  background-color: ${colors.white};
  margin-left: 0.8rem;
  border-radius: 3rem;
  border: 1px solid ${colors.white};
  padding: 1rem 1.9rem;
  max-width: fit-content;
  white-space: nowrap;
  transition: background-color 0.2s ease-out, border 0.2s ease-out, box-shadow 0.2s ease-out;

  &:hover,
  &:focus {
    background-color: ${colors.orange};
    box-shadow: 0px 0px 12px 0px ${colors.orange};
    border: ${colors.orange} 1px solid;
  }

  @media (min-width: 38em) {
    margin-left: 1.2rem;
  }
`;

const LinkLogin = styled.a.attrs({
  target: '_blank'
})`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${colors.white};
  background-color: transparent;
  margin-left: 0;
  border-radius: 3rem;
  border: 1px solid ${colors.white};
  padding: 1rem 1.9rem;
  max-width: fit-content;
  white-space: nowrap;
  transition: color 0.2s ease-out, border 0.2s ease-out, box-shadow 0.2s ease-out,
    text-shadow 0.2s ease-out;

  &:hover,
  &:focus {
    color: ${colors.orange};
    box-shadow: 0px 0px 12px 0px ${colors.orange};
    border: ${colors.orange} 1px solid;
    text-shadow: 0px 1px 10px rgba(255, 179, 28, 1);
  }

  @media (min-width: 38em) {
    margin-right: 0;
  }
`;

export default function UserMenu() {
  const { t } = useTranslation();
  return (
    <Container>
      <LinkLogin href={loginLink}>{t('GLOBAL.SIGN')}</LinkLogin>
      <LinkRegistration href={signLink}>{t('GLOBAL.CREATE_ACCOUNT')}</LinkRegistration>
    </Container>
  );
}
