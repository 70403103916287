import { useState } from "react";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";
import CookiesPopup from "./CookiesPopup";

import IntObsElement from "./Header/components/intObservElement";

const Main = styled.main`
  width: 100%;
  margin-top: 6.2rem;
`;

const Layout = ({ children }) => {
  const [headerType, setHeaderType] = useState("main");
  const changeHeader = (variant) => {
    if (variant === "1") {
      setHeaderType("main");
    }
    if (variant === "2") {
      setHeaderType("alternative");
    }
    return;
  };
  return (
    <>
      <IntObsElement func={changeHeader} />
      <Header view={headerType} />
      <CookiesPopup />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
