import { createSlice } from '@reduxjs/toolkit';
import i18next from '../../helpers/i18next'

export const initialState = {
  loading: false,
  lang: 'en',
};

const startLoading = (state) => {
  state.loading = true;
};

const endLoading = (state) => {
  state.loading = false;
};

const setLang = (state, {payload}) => {
  state.lang = payload;
  i18next.changeLanguage(payload)
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
      startLoading,
      endLoading,
      setLang,
    },
  });

export default globalSlice.reducer;
export const actions = { ...globalSlice.actions };