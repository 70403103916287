import styled, { keyframes } from 'styled-components'
import { colors } from '../helpers/consts'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 25;
  background-color: rgba(0,0,0,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`

export const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  div {
    animation: ${rotateAnimation} 1.1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 4rem 4rem;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: ${colors.orange};
      margin: -0.4rem 0 0 -0.4rem;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;
    }

    &:nth-child(1):after {
      top: 6.3rem;
      left: 6.3rem;
    }

    &:nth-child(2) {
      animation-delay: -0.072s;
    }

    &:nth-child(2):after {
      top: 6.8rem;
      left: 5.6rem;
    }

    &:nth-child(3) {
      animation-delay: -0.108s;
    }

    &:nth-child(3):after {
      top: 7.1rem;
      left: 4.8rem;
    }

    &:nth-child(4) {
      animation-delay: -0.144s;
    }

    &:nth-child(4):after {
      top: 7.2rem;
      left: 4.0rem;
    }

    &:nth-child(5) {
      animation-delay: -0.18s;
    }

    &:nth-child(5):after {
      top: 7.1rem;
      left: 3.2rem;
    }

    &:nth-child(6) {
      animation-delay: -0.216s;
    }

    &:nth-child(6):after {
      top: 6.8rem;
      left: 2.4rem;
    }

    &:nth-child(7) {
      animation-delay: -0.252s;
    }

    &:nth-child(7):after {
      top: 6.3rem;
      left: 1.7rem;
    }

    &:nth-child(8) {
      animation-delay: -0.288s;
    }

    &:nth-child(8):after {
      top: 5.6rem;
      left: 1.2rem;
    }
  }
`

const Preloader = () => {
  const inner = <Spinner>
    <div key={Math.random()} />
    <div key={Math.random()} />
    <div key={Math.random()} />
    <div key={Math.random()} />
    <div key={Math.random()} />
    <div key={Math.random()} />
    <div key={Math.random()} />
    <div key={Math.random()} />
  </Spinner>


  return <Wrapper>{inner}</Wrapper>
}

export default Preloader