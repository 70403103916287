import {NavLink} from "react-router-dom";

export const Navigation = () => {

    return <nav className='legal-page-container__nav'>
        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/terms-of-use"
        >Terms of use</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/privacy-policy"
        >Privacy policy</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/comprehensive-privacy-policy"
        >Comprehensive privacy policy</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/risk-warning"
        >Risk-warning</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/regulatory-supervision"
        >Regulatory-supervision</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/impressum"
        >Impressum</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/cookie-statement"
        >Cookie statement</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/aml-ctf-policy"
        >Aml ctf policy</NavLink>

        <NavLink
            className={({isActive}) => isActive ? 'active' : ''}
            to="/legal/trademarks"
        >Trademarks</NavLink>
    </nav>
}
