import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18NextHttpBackend from 'i18next-http-backend'

i18n
  .use(initReactI18next)
  .use(i18NextHttpBackend)
  .init({
    lng: navigator.language.split('-')[0] || 'en',
    fallbackLng: 'en',
    backend: { loadPath: '/locales/{{lng}}.json' },
  })

export default i18n
