import { lazy } from "react";
import routesPaths from "./routesPaths";

const MainPage = lazy(() => import("../pages/MainPage" /* webpackChunkName: "MainPage" */))
const AboutPage = lazy(() => import("../pages/AboutPage" /* webpackChunkName: "AboutPage" */))
const BusinessAccountPage = lazy(() => import("../pages/BusinessAccountPage" /* webpackChunkName: "BusinessAccountPage" */))
// const GupBusinessPage = lazy(() => import("../pages/GupBusinessPage" /* webpackChunkName: "GupBusinessPage" */))
// const PaymentsPage = lazy(() => import("../pages/PaymentsPage" /* webpackChunkName: "PaymentsPage" */))
const ContactsPage = lazy(() => import("../pages/ContactsPage" /* webpackChunkName: "ContactsPage" */))
// const HelpPage = lazy(() => import("../pages/HelpPage" /* webpackChunkName: "HelpPage" */))
const CardsPage = lazy(() => import("../pages/CardsPage" /* webpackChunkName: "CardsPage" */))
// const NewsPage = lazy(() => import("../pages/NewsPage" /* webpackChunkName: "NewsPage" */))
const PrivateAccountPage = lazy(() => import("../pages/PrivateAccountPage" /* webpackChunkName: "PrivateAccountPage" */))
const SecurityPage = lazy(() => import("../pages/SecurityPage" /* webpackChunkName: "SecurityPage" */))
// const PartnersPage = lazy(() => import("../pages/PartnersPage" /* webpackChunkName: "PartnersPage" */))
const CommissionsPage = lazy(() => import("../pages/CommissionsPage" /* webpackChunkName: "CommissionsPage" */))

const routes = [
  {
    key: "main",
    path: routesPaths.main,
    label: 'HEADER.MAIN',
    element: <MainPage />,
  },
  {
    key: "business",
    label: 'HEADER.BUSSINES',
    mainNav: true,
    withSubMenu: true,
    subMenu: [
      {
        key: "businessAcc",
        path: routesPaths.businessAcc,
        label: 'HEADER.REGISTER_BUSSINES',
      },
      {
        key: "security",
        path: routesPaths.security,
        label: 'HEADER.SECURITY',
      },
      // {
      //   key: "payments",
      //   path: routesPaths.payments,
      //   label: 'HEADER.PAYMENTS',
      // },
      // {
      //   key: "partners",
      //   path: routesPaths.partners,
      //   label: 'HEADER.PARTNERS',
      // },
      // {
      //   key: "gupBusiness",
      //   path: routesPaths.gupBusiness,
      //   label: 'HEADER.GUP_BUSSINES',
      // },
      {
        key: "commissions",
        path: routesPaths.commissions,
        label: 'HEADER.COMMISIONS',
      },
    ],
  },
  {
    key: "forPrivates",
    label: 'HEADER.PRIVACY',
    mainNav: true,
    withSubMenu: true,
    subMenu: [
      {
        key: "privateAcc",
        path: routesPaths.privateAcc,
        label: 'HEADER.GUP_WORK',
      },
      {
        key: "cards",
        path: routesPaths.cards,
        label: 'HEADER.CARDS',
      },
      {
        key: "commissions",
        path: routesPaths.commissions,
        label: 'HEADER.COMMISIONS',
      },
    ],
  },
  // {
  //   key: "news",
  //   path: routesPaths.news,
  //   label: 'HEADER.NEWS',
  //   element: <NewsPage />,
  //   mainNav: true,
  // },
  {
    key: "company",
    label: 'HEADER.COMPANY',
    mainNav: true,
    withSubMenu: true,
    subMenu: [
      {
        key: "contacts",
        path: routesPaths.contacts,
        label: 'HEADER.CONTACTS',
      },
      {
        path: routesPaths.about,
        key: "about",
        label: 'HEADER.ABOUT',
      },
    ],
  },
  // {
  //   key: "help",
  //   path: routesPaths.help,
  //   label: 'HEADER.HELP',
  //   element: <HelpPage />,
  //   mainNav: true,
  // },
  {
    key: "businessAcc",
    path: routesPaths.businessAcc,
    label: 'HEADER.REGISTER_BUSSINES',
    element: <BusinessAccountPage />,
  },
  {
    key: "security",
    path: routesPaths.security,
    label: 'HEADER.SECURITY',
    element: <SecurityPage />,
  },
  // {
  //   key: "payments",
  //   path: routesPaths.payments,
  //   label: 'HEADER.PAYMENTS',
  //   element: <PaymentsPage />,
  // },
  // {
  //   key: "partners",
  //   path: routesPaths.partners,
  //   label: 'HEADER.PARTNERS',
  //   element: <PartnersPage />,
  // },
  // {
  //   key: "gupBusiness",
  //   path: routesPaths.gupBusiness,
  //   label: 'HEADER.GUP_BUSSINES',
  //   element: <GupBusinessPage />,
  // },
  {
    key: "contacts",
    path: routesPaths.contacts,
    label: 'HEADER.CONTACTS',
    element: <ContactsPage />,
  },
  {
    key: "commissions",
    path: routesPaths.commissions,
    label: 'HEADER.COMMISIONS',
    element: <CommissionsPage />,
  },
  {
    key: "about",
    path: routesPaths.about,
    label: 'HEADER.ABOUT',
    element: <AboutPage />,
  },
  {
    key: "cards",
    path: routesPaths.cards,
    label: 'HEADER.CARDS',
    element: <CardsPage />,
  },
  {
    key: "privateAcc",
    path: routesPaths.privateAcc,
    label: 'HEADER.GUP_WORK',
    element: <PrivateAccountPage />,
  },
];

export default routes;
