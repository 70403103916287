import {Outlet, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {Navigation} from "../components/LegalPage/Navigation";

import '../components/LegalPage/LegalPage.scss'

export const LegalPage = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='legal-page-container'>

            <div>
                <Navigation />
            </div>

            <div className='legal-page-container__content'>
                <Outlet />
            </div>
        </div>
    )
}
