export const siteName = 'Global Unit Pay';
export const siteEmail = 'info@globalunitpay.ch';
export const domain = 'https://portal.globalunitpay.ch';
export const signLink = `${domain}/sign-up`;
export const loginLink = `${domain}/sign-in`;
export const cookiesLink = `${domain}/legal/cookie-statement`;
export const legalLink = `${domain}/legal`;
export const privacyPolicyLink = `${domain}/legal/privacy-policy`;

export const appStoreLink = 'https://apps.apple.com/en/app/global-unit-pay';
export const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.global_unit_pay_mobile';

export const socialLinks = {
  facebook: { en: 'https://www.facebook.com/groups/5518452468227732', ru: 'https://www.facebook.com/groups/2886880391640246' },
  telegram: { en: 'https://t.me/globalunitpay', ru: 'https://t.me/globalunitpayru' },
  // vk: { en: 'https://vk.com/globalunitpayen', ru: 'https://vk.com/public204681020' },
  linkedin: { en: 'https://www.linkedin.com/company/global-unit-pay/', ru: 'https://www.linkedin.com/groups/12545869' },
  instagram: { en: 'https://www.instagram.com/globalunitpay/', ru: 'https://www.instagram.com/globalunitpay_ru' },
  twitter: { en: 'https://twitter.com/globalunitpayen', ru: 'https://twitter.com/globalunitpayru' },
  youtube: { en: 'https://youtube.com/c/GlobalUnitPay', ru: 'https://youtube.com/c/GlobalUnitPay' },
}

export const colors = {
  darkBlue: '#0d0c52',
  alternativeDarkBlue: '#070643',
  lightBlue: '#f5f7fc',
  darkViolet: '#080845',
  orange: '#ffb21c',
  black: '#000',
  lightBlack: '#333333',
  white: '#fff',
}