import {useTranslation} from 'react-i18next'
import {themeConfig, contactsURL} from '../theme'

const { COMPANY_NUMBER } = themeConfig

export const Impressum = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.Impressum.Legal`)}</h1>
            <h3>Full legal name of company</h3>
            <p>GUP-CH AG</p>
            <h3>Website</h3>
            <p>www.globalunitpay.ch</p>
            <h3>Regulatory Supervision</h3>
            <p>
                GUP-CH AG is affiliated to SO-FIT a financial intermediary within the meaning of Article 2 para. 3 of 
                the Anti-Money Laundering Act (AMLA).
            </p>
            <p>
                SO-FIT is a self-regulatory organization recognized by the Swiss Federal Financial Markets Supervisory 
                Authority (FINMA).
            </p>

            <h3>Anti Money Laundering (AML)</h3>
            <p>
                GUP-CH AG (GUP) is subject to provisions on Anti Money Laundering (AML) under the laws of Switzerland,
                which are in accordance with the FATF recommendations. Switzerland is a member
                country of the Financial Action Task Force (FATF). This means, in particular, that GUP has to identify
                its customers and establish the beneficial owner's identity. The AML compliance
                policies approved by the Management Board include inter alia the processes for the identification of the
                customers and establishing the identity of the beneficial owner. The
                policies also cover the collection of information regarding the customers' business activities,
                relationships with Politically Exposed Persons, and record retention procedures.
                Furthermore, GUP regularly provides AML training to relevant employees and does not provide banking
                services to any bank that does not maintain a physical presence in any country
                and is not a regulated affiliate. The anti-money laundering policies are applicable to head office and
                branches alike and are in accordance with the Wolfsberg anti-money
                laundering principles.
            </p>

            <h3>Compliance</h3>
            <p>
                When you contact compliance by email, please use the contact form on the&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;page of our website.
            </p>

            <h3>Registered office address</h3>
            <p>Chaltenbodenstrasse 16, 8834 Schindellegi, Switzerland</p>

            <h3>Canton</h3>
            <p>Schwyz</p>

            <h3>Company number (UID)</h3>
            <p>
                <a
                    href={`https://zh.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=${COMPANY_NUMBER}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {COMPANY_NUMBER}
                </a>
            </p>

            <h3>Date of formation</h3>
            <p>16.05.2008</p>

            <h3>Type of Corporation</h3>
            <p>Aktiengesellschaft (Swiss Public Limited Company)</p>

            <h3>Confirm license:</h3>
            <p>
                <a
                    href="https://www.finma.ch/en/authorisation/self-regulatory-organisations-sros/sro-member-search/"
                    target="_blank"
                    rel="noreferrer"
                >
                    FINMA
                </a>
            </p>
        </div>
    )
}
